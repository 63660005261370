import { LinkInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';
import LinkRenderer from './link-renderer';

/* eslint-disable-next-line */
export interface LinksRendererProps {
  cta: LinkInterface[];
  className?: string;
}

export function LinksRenderer({ cta, className }: LinksRendererProps) {
  if (!cta || isEmpty(cta)) {
    return null;
  }

  return (
    <div className={`${className}`}>
      {cta.map((cta, index) => (
        <LinkRenderer key={index} cta={cta} />
      ))}
    </div>
  );
}

export default LinksRenderer;
