import { CACHE_TAG } from '@qlevr/shared/constants';
import { revalidateTag } from 'next/cache';
import { addToCartBulk } from '../../cart';

export async function addItemsBulk(locale: string, cartId: string, items: { id: string; quantity: number }[]) {
  if (!items.length) {
    return 'Items are required';
  }

  try {
    const res = await addToCartBulk(locale, cartId, items);

    revalidateTag(CACHE_TAG.productRecommendations);
    revalidateTag(CACHE_TAG.cart);

    return res;
  } catch (error) {
    return 'Error adding items';
  }
}
