import { PAGE_TYPES } from '@qlevr/shared/constants';
import { BlogCardInterface, MetaInterface } from '@qlevr/shared/interfaces';
import { blogCardsMapper } from '@qlevr/shared/mappers';
import { BlogStoryblok } from '@qlevr/shared/schema';
import { StoryblokStory } from 'storyblok-generate-ts';
import { FetchStoryblokStoriesService } from './storyblok/fetch-storyblok-stories';

export class FetchBlogsByCategory extends FetchStoryblokStoriesService<BlogCardInterface, StoryblokStory<BlogStoryblok>> {
  constructor(locale: string, preview: boolean, filter: string) {
    super({
      pageType: PAGE_TYPES.blog,
      locale,
      preview,
      filter: [
        {
          key: `filter_query[categories][all_in_array]`,
          value: filter,
        }
      ]
    });
  }

  protected override mapper(data: StoryblokStory<BlogStoryblok>[], meta: MetaInterface): BlogCardInterface[] {
    return blogCardsMapper(data, meta);
  }
}
