'use client';

import { Modal } from 'flowbite-react';
import { useState } from 'react';
import { filtersModalTheme } from './filters-modal-theme';
import FiltersModalBody from './filters-modal-body';
import { FilterShopify } from '@qlevr/shared/schema';
import LinkRenderer from '../link/link-renderer';
import { BUTTON_SIZE, ICONS, LINK_STYLES } from '@qlevr/shared/constants';
import { useTranslations } from 'next-intl';

export interface FiltersModalProps {
  shopifyFilters: FilterShopify[];
}

export function FiltersModal({ shopifyFilters }: FiltersModalProps) {
  const t = useTranslations();
  const [isOpen, setIsOpen] = useState(false);

  if (!shopifyFilters) return null;

  return (
    <>
      <LinkRenderer
        cta={{
          label: t('collection.filters.filterBtn'),
          as: 'button',
          onClick: () => setIsOpen(true),
          linkStyle: LINK_STYLES.secondary,
          size: BUTTON_SIZE.small,
          trailingIcon: ICONS.filter,
          trailingClassName: `w-4 h-4`,
          wrapperClassName: ``,
        }}
      />
      <Modal
        dismissible
        size="md"
        theme={filtersModalTheme}
        show={isOpen}
        position={'top-right'}
        onClose={() => setIsOpen(false)}
        className="cart-modal-overlay bg-gray-800"
      >
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <FiltersModalBody shopifyFilters={shopifyFilters} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export default FiltersModal;
