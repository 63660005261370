import { RATIOS } from '@qlevr/shared/constants';
import {
  CollectionCardInterface,
  KeyValueInterface,
  LinkedMetaDataInterface,
  MetaInterface,
  ProductInterface,
  ProductOptionInterface,
} from '@qlevr/shared/interfaces';
import { CollectionShopify } from '@qlevr/shared/schema';
import { escapeUrl, removeForbiddenIdChars, reverseEscapeUrl } from '@qlevr/shared/utilities';
import isEmpty from 'lodash.isempty';
import { imageShopifyMapper } from '../image/image-shopify';
import { mapProductSizeOptions } from '../products/product-size-options';
import { productsMapper } from '../products/products-shopify';
import { getCollectionSlug, getCollectionSlugFilter } from './collection-slug';

export function metaCollectionsMapper(
  collections: { [id: string]: CollectionShopify },
  linkedData: LinkedMetaDataInterface,
): CollectionCardInterface[] {
  return Array.from(linkedData.collections).reduce((acc, id) => {
    const collectionShopify = collections[removeForbiddenIdChars(id)];
    const collection = collectionMapper(collectionShopify);
    if (collection) {
      acc.push(collection);
    }

    return acc;
  }, [] as CollectionCardInterface[]);
}

export function getMetaCollectionsById(items: string[], meta: MetaInterface): CollectionCardInterface[] {
  return items.reduce((acc, id) => {
    const collection = meta.collections?.find((item) => item.id === id);

    if (collection) {
      acc.push(collection);
    }
    return acc;
  }, [] as CollectionCardInterface[]);
}

export function collectionMapper(item: CollectionShopify): CollectionCardInterface {
  const products = productsMapper(item?.products?.edges, true);

  return {
    id: item?.id ?? null,
    slug: getCollectionSlug(item?.handle),
    shopifyFilters: item?.products?.filters,
    filters: collectionFilters(products, item?.handle),
    title: item?.title ?? null,
    text: item?.description ?? null,
    image: imageShopifyMapper(item?.image?.url, {
      ratio: RATIOS.DEFAULT,
      altText: item?.image?.altText,
      columnConfig: { xs: 1, lg: 4 },
    }),
    products: collectionProductByColorAndSizeVariant(products) ?? [],
    description: item?.seo?.description ?? null,
  };
}

export function collectionFilteredProductsMapper(
  item: CollectionShopify,
  filter: string | undefined,
): CollectionCardInterface {
  const products = productsMapper(item?.products?.edges);

  return {
    id: item?.id ?? null,
    slug: getCollectionSlug(item?.handle),
    shopifyFilters: item?.products?.filters,
    filters: collectionFilters(products, item?.handle),
    title: item?.title ?? null,
    text: item?.description ?? null,
    image: imageShopifyMapper(item?.image?.url, {
      ratio: RATIOS.DEFAULT,
      altText: item?.image?.altText,
      columnConfig: { xs: 1, lg: 4 },
    }),
    products: collectionProductByFilteredSizeVariant(products, filter) ?? [],
    description: item?.seo?.description ?? null,
  };
}

export function collectionProductByFilteredSizeVariant(
  products: ProductInterface[],
  filter: string | undefined,
): ProductInterface[] {
  const items = [] as ProductInterface[];

  for (const product of products) {
    if (!product.combinations || isEmpty(product.combinations)) {
      items.push(product);
      continue;
    }

    const sizeColorSet = new Set<string>();
    // Collect all unique size and color combinations
    for (const combination of product.combinations) {
      if (combination.size && combination.color) {
        sizeColorSet.add(`${combination.size}:${combination.color}`);
      }
    }

    if (isEmpty(sizeColorSet)) {
      items.push(product);
      continue;
    }

    // Generate products for each unique size-color combination
    for (const sizeColor of sizeColorSet) {
      const [size, color] = sizeColor.split(':');
      // Check if the current size matches the filter, if a filter is set
      if (filter && size !== filter) {
        continue; // Skip this size-color if it does not match the filter
      }

      const colorVariant = product.variants?.find(
        (variant) =>
          variant.options.find((option) => {
            return option.name === 'color' && option.value === color && variant.availableForSale;
          }) &&
          variant.options.find((option) => {
            return option.name === 'size' && option.value === size;
          }),
      );

      const contextualImage =
        colorVariant?.images && colorVariant.images.length > 1
          ? colorVariant.images[1].image
          : colorVariant?.featuredImage;

      if (!colorVariant?.featuredImage) {
        continue; // Skip if no featured image is found for this variant
      }

      const productOptions = product.options?.filter((option) => {
        return (
          (option.name === 'size' && option.values.includes(size)) ||
          (option.name === 'color' && option.values.includes(color))
        );
      });
      // Create a new product entry with the specific size and color variant details
      items.push({
        ...product,
        options: productOptions || [],
        featuredImage: colorVariant.featuredImage,
        productCombinationColor: reverseEscapeUrl(color),
        productCombinationSize: reverseEscapeUrl(size),
        contextualImage: contextualImage,
        id: colorVariant.id,
        slug: `${product.slug}?size=${size}&color=${color}`,
      });
    }
  }

  return items;
}

export function collectionProductByColorAndSizeVariant(products: ProductInterface[]): ProductInterface[] {
  const items = [] as ProductInterface[];
  for (const product of products) {
    if (!product.combinations || isEmpty(product.combinations)) {
      items.push(product);
      continue;
    }

    const sizeColorSet = new Set<string>();
    // Collect all unique size and color combinations
    for (const combination of product.combinations) {
      if (combination.size && combination.color) {
        sizeColorSet.add(`${combination.size}:${combination.color}`);
      }
    }

    if (isEmpty(sizeColorSet)) {
      items.push(product);
      continue;
    }

    // Generate products for each unique size-color combination
    for (const sizeColor of sizeColorSet) {
      const [size, color] = sizeColor.split(':');
      const colorVariant = product.variants?.find(
        (variant) =>
          variant.options.find((option) => {
            return option.name === 'color' && option.value === color && variant.availableForSale;
          }) &&
          variant.options.find((option) => {
            return option.name === 'size' && option.value === size;
          }),
      );

      const contextualImage =
        colorVariant?.images && colorVariant.images.length > 1
          ? colorVariant.images[1].image
          : colorVariant?.featuredImage;

      if (!colorVariant?.featuredImage) {
        continue; // Skip if no featured image is found for this variant
      }
      const productOptions: ProductOptionInterface[] = product.options?.map((option) => {
        switch (option.name) {
          case 'color':
            return { ...option, values: [color] };
          case 'size':
            return { ...option, values: [size] };
          default:
            return option;
        }
      }) as ProductOptionInterface[];

      // Create a new product entry with the specific size and color variant details
      items.push({
        ...product,
        options: productOptions || [],
        featuredImage: colorVariant.featuredImage,
        productCombinationColor: reverseEscapeUrl(color),
        productCombinationSize: reverseEscapeUrl(size),
        contextualImage: contextualImage,
        id: colorVariant.id,
        slug: `${product.slug}?size=${size}&color=${color}`,
      });
    }
  }

  return items;
}

/**
 *
 * @param products
 * @param handle
 * @returns list of urls collection/[slug]/[filter]
 * the value for the filter is a unique set of product.variant.option.size
 */
function collectionFilters(products: ProductInterface[], handle: string): KeyValueInterface[] {
  const filters = mapProductSizeOptions(products);
  return filters.map((filter) => {
    const escapedFilter = escapeUrl(filter);
    return {
      key: filter,
      value: getCollectionSlugFilter(handle, escapedFilter),
    };
  });
}
