'use client';
import { BUTTON_SIZE, LINK_STYLES } from '@qlevr/shared/constants';
import { ContentProductBundlerInterface, ProductVariantInterface } from '@qlevr/shared/interfaces';
import { ProductBundlerContext } from '@qlevr/shared/providers';
import { getDiscountedPriceForItem, getVariantForProduct } from '@qlevr/shared/utilities';
import { useContext, useEffect, useState } from 'react';
import Container from '../../../container/container';
import PaymentMethods from '../../../payment-methods/payment-methods';
import ProductBulkAdd from '../../../product-status/product-bulk-add';
import ProductDeliveryNote from '../../../product/product-delivery-note';
import ProductAccordion from '../../../product/product-specifications/product-accordion';
import StarRating from '../../../rating/star-rating';
import Section from '../../../section/section';
import Text from '../../../text/text';
import Title from '../../../title/title';
import ProductBundlerCard from '../bundle-products/product-bundler-card';
import ProductBundlerMainProductCard from './product-bundler-main-product-card';
import ProductBundlerVariantSelector from './product-bundler-variant-selector';
import QuizStackedImages from './quiz-stacked-images';
import { useTranslations } from 'next-intl';

/* eslint-disable-next-line */
export interface ContentProductBundlerProps extends ContentProductBundlerInterface {}

export function ContentProductBundler({
  title,
  text,
  product,
  products,
  deliveryNotes,
  section,
}: ContentProductBundlerProps) {
  const { variantIds } = useContext(ProductBundlerContext);
  const [loading, setLoading] = useState(true);
  const t = useTranslations();

  useEffect(() => {
    if (loading && variantIds.length > 1) setLoading(false);
  }, [loading, variantIds]);

  if (!product) {
    return null;
  }

  const productVariant = getVariantForProduct(product, variantIds);

  // combine the variants of main product and bundled products
  const variants = [product, ...products].map((item) => item.variants).flat();

  // Filter the variants whose IDs are in `variantIds` ( varaint ids that are getting added to the cart )
  const filteredVariants = variants.filter(
    (variant): variant is ProductVariantInterface => variant !== null && variantIds.includes(variant.id),
  );

  const quizStackedImages: string[] = [];
  filteredVariants.forEach((variant) => {
    if (variant.quizStackedImage) {
      quizStackedImages.push(variant.quizStackedImage);
    }
  });

  // Calculate the total price from the filtered variants ( strike through price )
  const totalPriceOfFilteredVariants = parseFloat(
    filteredVariants
      .reduce((acc, variant) => {
        if (variant.price) {
          return acc + variant.price.amount;
        }
        return acc;
      }, 0)
      .toFixed(2),
  );

  // Calculate the discounted price for the main product variant + bundled product variants
  const productDiscountedPrice = getDiscountedPriceForItem(product, productVariant);

  const productsDiscountedPrices = products.map((product) => {
    const variant = getVariantForProduct(product, variantIds);
    return getDiscountedPriceForItem(product, variant);
  });

  const discountedPrices = [productDiscountedPrice, ...productsDiscountedPrices];

  const sumOfDiscountedPrice = parseFloat(
    discountedPrices
      .filter((price): price is number => price !== null)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      .toFixed(2),
  );

  const showDiscountedprice =
    sumOfDiscountedPrice < totalPriceOfFilteredVariants && totalPriceOfFilteredVariants !== sumOfDiscountedPrice;

  // Create the VariantWithQuantity array
  const variantWithQuantity = filteredVariants.map((variant) => ({
    variantId: variant.id,
    quantity: 1,
  }));

  return (
    <Section {...section} className="w-full">
      <Container className="relative space-y-6">
        {loading && (
          <div className="absolute inset-0 z-10 flex items-start justify-center">
            <div className="loading"></div>
          </div>
        )}
        <div
          className={`grid grid-cols-1 gap-4 transition-all duration-500 lg:grid-cols-12 lg:gap-6 ${!loading ? 'opacity-100' : 'opacity-0'} `}
        >
          <div className="col-span-1 aspect-square h-fit lg:sticky lg:top-3 lg:col-span-6 lg:aspect-auto">
            <QuizStackedImages quizStackedImages={quizStackedImages} mainProductImages={product.images} />
          </div>
          <div className="col-span-1 w-full lg:col-span-6">
            {title && <Title {...title} section={section} />}
            <div className="mt-0 flex flex-wrap justify-between gap-x-5 gap-y-3 border-y-2 border-slate-300 py-2">
              <StarRating className="text-kiss-500" section={section} reviews={product.review?.averageScore} />
              <div className="flex items-center gap-1">
                {totalPriceOfFilteredVariants > 0 && showDiscountedprice && (
                  <div className={`${section.theme.badge} w-fit rounded-full px-4 py-1 text-xs font-bold capitalize`}>
                    {t('saleBadge')}
                  </div>
                )}
                <div className="flex gap-2 text-lg">
                  {totalPriceOfFilteredVariants > 0 && showDiscountedprice && (
                    <span className="text-slate-400 line-through">
                      {product?.priceRange?.minVariantPrice.symbol}
                      {totalPriceOfFilteredVariants.toFixed(2)}
                    </span>
                  )}
                  {sumOfDiscountedPrice > 0 && (
                    <span>
                      {product?.priceRange?.minVariantPrice.symbol}
                      {sumOfDiscountedPrice.toFixed(2)}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-6 mt-5 space-y-6 lg:my-6">
              {text && <Text className="text-base font-light" text={text} theme={section.theme} />}
              <ProductBundlerVariantSelector product={product} />
            </div>
            <div className="space-y-2">
              <ProductBundlerMainProductCard product={product} />
              {products.map((item) => (
                <ProductBundlerCard key={item.id} product={item} />
              ))}
            </div>
            <div className="pb-4 pt-6 lg:py-10">
              {variants && variants.length > 0 && (
                <ProductBulkAdd
                  cta={{
                    linkStyle: LINK_STYLES.primary,
                    size: BUTTON_SIZE.small,
                    className: 'text-white',
                  }}
                  variantWithQuantity={variantWithQuantity}
                  variants={filteredVariants}
                />
              )}
            </div>
            <ProductDeliveryNote
              className={`${section.theme.deliveryNote.background}`}
              notes={deliveryNotes}
              section={section}
            />
            <div className="pb-10 pt-4 lg:pb-6 lg:pt-4">
              <PaymentMethods />
            </div>
            <ProductAccordion specifications={product.productSpecifications} />
          </div>
        </div>
      </Container>
    </Section>
  );
}

export default ContentProductBundler;
