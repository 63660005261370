import { CategoryInterface } from '@qlevr/shared/interfaces';
import { CategoryStoryblok } from '@qlevr/shared/schema';
import { StoryblokStory } from 'storyblok-generate-ts';

export function categoriesMapper(categories: StoryblokStory<CategoryStoryblok>[]): CategoryInterface[] {
  return categories?.map((item) => categoryMapper(item)) ?? [];
}

export function categoryMapper(page: StoryblokStory<CategoryStoryblok>): CategoryInterface {
  return {
    name: page.name,
    uuid: page.uuid,
    description: page.content.description,
  };
}
