import { CACHE_TAG } from '@qlevr/shared/constants';
import { CartUpsellProducts } from '@qlevr/shared/interfaces';
import {
  cartMapper,
  cartUpsellProductsMapper,
  getCartProductIds,
  shopifyCartUpsellQueryBuilder,
} from '@qlevr/shared/mappers';
import { AttributeInputShopify, GetCartQueryShopify, GetCartUpsellProductsQueryShopify } from '@qlevr/shared/schema';
import {
  getCountryCodeShopify,
  getCountryFromCountryLocale,
  getLanguageCodeShopify,
  getLocaleFromCountryLocale,
} from '@qlevr/shared/utilities';
import isEmpty from 'lodash.isempty';
import { ShopifyService, shopifyClient } from './shopify/shopify-service';

export async function createCart(locale: string) {
  const country = getCountryCodeShopify(getCountryFromCountryLocale(locale));
  const language = getLanguageCodeShopify(getLocaleFromCountryLocale(locale));

  const res = await ShopifyService({ cache: 'no-store' }).createCart({ country, language });
  return cartMapper(res.cartCreate?.cart, [] as CartUpsellProducts[]);
}

export async function getCart(locale: string, cartId?: string) {
  if (!cartId) {
    return await createCart(locale);
  }

  const country = getCountryCodeShopify(getCountryFromCountryLocale(locale));
  const language = getLanguageCodeShopify(getLocaleFromCountryLocale(locale));

  const res = await ShopifyService({ cache: 'no-store', tags: [CACHE_TAG.cart] }).getCart({
    country,
    language,
    cartId,
  });

  // Get upsell products
  const productIds = getCartProductIds(res.cart);
  if (!productIds || isEmpty(productIds)) {
    return cartMapper(res.cart, null, res.metaobject);
  }

  const query = shopifyCartUpsellQueryBuilder(country, language, productIds);
  const recommendedProductsRes = await shopifyClient({
    cache: 'no-store',
    tags: [CACHE_TAG.productRecommendations],
  }).request<{
    [id: string]: GetCartUpsellProductsQueryShopify['productRecommendations'];
  }>(query);
  const recommendedProducts = cartUpsellProductsMapper(res.cart, recommendedProductsRes, locale);

  return cartMapper(res.cart, recommendedProducts, res.metaobject);
}

export async function addToCart(
  locale: string,
  cartId: string,
  variantId: string,
  attributes: AttributeInputShopify[],
) {
  const country = getCountryCodeShopify(getCountryFromCountryLocale(locale));
  const language = getLanguageCodeShopify(getLocaleFromCountryLocale(locale));

  try {
    const lineItems = [{ merchandiseId: variantId, quantity: 1, attributes }];
    const res = await ShopifyService({ cache: 'no-store', tags: [CACHE_TAG.cart] }).addCartItem({
      country,
      language,
      cartId,
      lineItems,
    });

    const upsell = await getCartUpsell(locale, res.cartLinesAdd?.cart);

    return cartMapper(res.cartLinesAdd?.cart, upsell);
  } catch (error) {
    console.error(`CartService addToCart error: ${error}`);
    return null;
  }
}

export async function addItemsToCart(
  locale: string,
  cartId: string,
  lineItems: { merchandiseId: string; quantity: number }[],
) {
  const country = getCountryCodeShopify(getCountryFromCountryLocale(locale));
  const language = getLanguageCodeShopify(getLocaleFromCountryLocale(locale));

  try {
    const res = await ShopifyService({ cache: 'no-store', tags: [CACHE_TAG.cart] }).addCartItem({
      country,
      language,
      cartId,
      lineItems,
    });

    const upsell = await getCartUpsell(locale, res.cartLinesAdd?.cart);

    return cartMapper(res.cartLinesAdd?.cart, upsell);
  } catch (error) {
    console.error(`CartService addToCart error: ${error}`);
    return null;
  }
}

export async function addToCartBulk(locale: string, cartId: string, items: { id: string; quantity: number }[]) {
  const country = getCountryCodeShopify(getCountryFromCountryLocale(locale));
  const language = getLanguageCodeShopify(getLocaleFromCountryLocale(locale));

  try {
    const lineItems = items.map((item) => ({ merchandiseId: item.id, quantity: item.quantity }));

    const res = await ShopifyService({ cache: 'no-store', tags: [CACHE_TAG.cart] }).addCartItem({
      country,
      language,
      cartId,
      lineItems,
    });

    const upsell = await getCartUpsell(locale, res.cartLinesAdd?.cart);

    return cartMapper(res.cartLinesAdd?.cart, upsell);
  } catch (error) {
    console.error(`CartService addToCartBulk error: ${error}`);
    return null;
  }
}

export async function updateCartItem(
  locale: string,
  cartId: string,
  lineItems: { id: string; merchandiseId: string; quantity: number }[],
) {
  const country = getCountryCodeShopify(getCountryFromCountryLocale(locale));
  const language = getLanguageCodeShopify(getLocaleFromCountryLocale(locale));

  try {
    const res = await ShopifyService({ cache: 'no-store', tags: [CACHE_TAG.cart] }).updateCartItem({
      country,
      language,
      cartId,
      lineItems,
    });

    const upsell = await getCartUpsell(locale, res.cartLinesUpdate?.cart);

    return cartMapper(res.cartLinesUpdate?.cart, upsell);
  } catch (error) {
    console.error(`CartService updateCartItem error: ${error}`);
    return null;
  }
}

export async function removeCartItem(locale: string, cartId: string, lineItemId: string) {
  const country = getCountryCodeShopify(getCountryFromCountryLocale(locale));
  const language = getLanguageCodeShopify(getLocaleFromCountryLocale(locale));

  try {
    const res = await ShopifyService({ cache: 'no-store', tags: [CACHE_TAG.cart] }).removeCartItem({
      country,
      language,
      cartId,
      lineItemId,
    });

    const upsell = await getCartUpsell(locale, res.cartLinesRemove?.cart);

    return cartMapper(res.cartLinesRemove?.cart, upsell);
  } catch (error) {
    console.error(`CartService removeCartItem error: ${error}`);
    return null;
  }
}

export async function getCartUpsell(
  locale: string,
  cart: GetCartQueryShopify['cart'],
): Promise<CartUpsellProducts[] | null> {
  // Get upsell products
  const productIds = getCartProductIds(cart);
  if (!productIds || isEmpty(productIds)) {
    return null;
  }

  const country = getCountryCodeShopify(getCountryFromCountryLocale(locale));
  const language = getLanguageCodeShopify(getLocaleFromCountryLocale(locale));

  const query = shopifyCartUpsellQueryBuilder(country, language, productIds);
  const recommendedProductsRes = await shopifyClient({
    cache: 'no-store',
    tags: [CACHE_TAG.productRecommendations],
  }).request<{
    [id: string]: GetCartUpsellProductsQueryShopify['productRecommendations'];
  }>(query);
  return cartUpsellProductsMapper(cart, recommendedProductsRes, locale);
}
