import { AnimatedImagesArrayMap, AnimatedImagesInterface, AnimatedImageType } from '@qlevr/shared/interfaces';

const aerosleepMattress: AnimatedImagesInterface[] = [
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0001-min.png?v=1720438504',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0002-min.png?v=1720438504',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0003-min.png?v=1720438504',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0004-min.png?v=1720438505',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0005-min.png?v=1720438505',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0006-min.png?v=1720438498',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0007-min.png?v=1720438500',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0008-min.png?v=1720438499',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0009-min.png?v=1720438500',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0010-min.png?v=1720438501',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0011-min.png?v=1720438501',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0012-min.png?v=1720438500',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0013-min.png?v=1720438500',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0014-min.png?v=1720438499',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0015-min.png?v=1720438501',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0016-min.png?v=1720438500',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0017-min.png?v=1720438500',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0018-min.png?v=1720438502',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0019-min.png?v=1720438500',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0020-min.png?v=1720438501',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0021-min.png?v=1720438501',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0022-min.png?v=1720438501',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0023-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0024-min.png?v=1720438501',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0025-min.png?v=1720438501',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0026-min.png?v=1720438500',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0027-min.png?v=1720438502',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0028-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0029-min.png?v=1720438501',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0030-min.png?v=1720438502',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0031-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0032-min.png?v=1720438502',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0033-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0034-min.png?v=1720438502',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0035-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0036-min.png?v=1720438504',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0037-min.png?v=1720438502',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0038-min.png?v=1720438502',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0039-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0040-min.png?v=1720438505',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0041-min.png?v=1720438505',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0042-min.png?v=1720438501',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0043-min.png?v=1720438501',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0044-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0045-min.png?v=1720438502',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0046-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0047-min.png?v=1720438502',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0048-min.png?v=1720438501',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0049-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0050-min.png?v=1720438502',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0051-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0052-min.png?v=1720438502',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0053-min.png?v=1720438502',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0054-min.png?v=1720438502',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0055-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0056-min.png?v=1720438502',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0057-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0058-min.png?v=1720438504',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0059-min.png?v=1720438504',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0060-min.png?v=1720438502',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0061-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0062-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0063-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0064-min.png?v=1720438504',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0065-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0066-min.png?v=1720438501',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0067-min.png?v=1720438501',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0068-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0069-min.png?v=1720438502',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0070-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0071-min.png?v=1720438502',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0072-min.png?v=1720438502',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0073-min.png?v=1720438502',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0074-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0075-min.png?v=1720438502',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0076-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0077-min.png?v=1720438501',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0078-min.png?v=1720438504',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0079-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0080-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0081-min.png?v=1720438502',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0082-min.png?v=1720438502',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0083-min.png?v=1720438504',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0084-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0085-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0086-min.png?v=1720438504',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0087-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0088-min.png?v=1720438502',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0089-min.png?v=1720438506',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0090-min.png?v=1720438505',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0091-min.png?v=1720438504',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0092-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0093-min.png?v=1720438504',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0094-min.png?v=1720438505',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0095-min.png?v=1720438505',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0096-min.png?v=1720438504',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0097-min.png?v=1720438505',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0098-min.png?v=1720438504',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0099-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0100-min.png?v=1720438505',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0101-min.png?v=1720438504',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0102-min.png?v=1720438505',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0103-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0104-min.png?v=1720438503',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0105-min.png?v=1720438505',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0106-min.png?v=1720438506',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0107-min.png?v=1720438508',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0108-min.png?v=1720438505',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0109-min.png?v=1720438506',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0110-min.png?v=1720438506',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0111-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0112-min.png?v=1720438506',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0113-min.png?v=1720438506',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0114-min.png?v=1720438506',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0115-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0116-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0117-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0118-min.png?v=1720438505',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0119-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0120-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0121-min.png?v=1720438506',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0122-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0123-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0124-min.png?v=1720438506',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0125-min.png?v=1720438506',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0126-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0127-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0128-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0129-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0130-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0131-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0132-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0133-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0134-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0135-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0136-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0137-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0138-min.png?v=1720438508',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0139-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0140-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0141-min.png?v=1720438506',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0142-min.png?v=1720438508',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0143-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0144-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0145-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0146-min.png?v=1720438508',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0147-min.png?v=1720438508',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0148-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0149-min.png?v=1720438508',
    width: 2048,
    height: 2048,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_scrolling_animation_0150-min.png?v=1720438507',
    width: 2048,
    height: 2048,
  },
];

const aerosleepMattressPremium: AnimatedImagesInterface[] = [
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_000.jpg?v=1721210540',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_001.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_002.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_003.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_004.jpg?v=1721210536',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_005.jpg?v=1721210541',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_006.jpg?v=1721210536',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_007.jpg?v=1721210536',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_008.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_009.jpg?v=1721210535',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_010.jpg?v=1721210536',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_011.jpg?v=1721210536',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_012.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_013.jpg?v=1721210536',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_014.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_015.jpg?v=1721210538',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_016.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_017.jpg?v=1721210536',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_018.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_019.jpg?v=1721210538',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_020.jpg?v=1721210538',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_021.jpg?v=1721210536',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_022.jpg?v=1721210538',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_023.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_024.jpg?v=1721210536',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_025.jpg?v=1721210535',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_026.jpg?v=1721210538',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_027.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_028.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_029.jpg?v=1721210536',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_030.jpg?v=1721210536',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_031.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_032.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_033.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_034.jpg?v=1721210536',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_035.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_036.jpg?v=1721210538',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_037.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_038.jpg?v=1721210536',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_039.jpg?v=1721210538',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_040.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_041.jpg?v=1721210538',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_042.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_043.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_044.jpg?v=1721210538',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_045.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_046.jpg?v=1721210538',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_047.jpg?v=1721210536',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_048.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_049.jpg?v=1721210538',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_050.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_051.jpg?v=1721210538',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_052.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_053.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_054.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_055.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_056.jpg?v=1721210536',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_057.jpg?v=1721210538',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_058.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_059.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_060.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_061.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_062.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_063.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_064.jpg?v=1721210538',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_065.jpg?v=1721210538',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_066.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_067.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_068.jpg?v=1721210536',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_069.jpg?v=1721210538',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_070.jpg?v=1721210538',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_071.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_072.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_073.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_074.jpg?v=1721210538',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_075.jpg?v=1721210538',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_076.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_077.jpg?v=1721210538',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_078.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_079.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_080.jpg?v=1721210540',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_081.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_082.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_083.jpg?v=1721210540',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_084.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_085.jpg?v=1721210541',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_086.jpg?v=1721210540',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_087.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_088.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_089.jpg?v=1721210540',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_090.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_091.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_092.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_093.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_094.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_095.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_096.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_097.jpg?v=1721210538',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_098.jpg?v=1721210537',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_099.jpg?v=1721210538',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_100.jpg?v=1721210540',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_101.jpg?v=1721210540',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_102.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_103.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_104.jpg?v=1721210540',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_105.jpg?v=1721210542',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_106.jpg?v=1721210542',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_107.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_108.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_109.jpg?v=1721210541',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_110.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_111.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_112.jpg?v=1721210542',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_113.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_114.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_115.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_116.jpg?v=1721210545',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_117.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_118.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_119.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_120.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_121.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_122.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_123.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_124.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_125.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_126.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_127.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_128.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_129.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_130.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_131.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_132.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_133.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_134.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_135.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_136.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_137.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_138.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_139.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_140.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_141.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_142.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_143.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_144.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_145.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_146.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_147.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_148.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_149.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_150.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_151.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_152.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_153.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_154.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_155.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_156.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_157.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_158.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_159.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_160.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_161.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_162.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_163.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_164.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_165.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_166.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_167.jpg?v=1721210542',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_168.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_169.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_170.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_171.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_172.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_173.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_174.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_175.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_176.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_177.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_178.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_179.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_180.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_181.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_182.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_183.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_184.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_185.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_186.jpg?v=1721210543',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_187.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_188.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_189.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_190.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_191.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_192.jpg?v=1721210544',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_193.jpg?v=1721210539',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_194.jpg?v=1721210541',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_195.jpg?v=1721210540',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_196.jpg?v=1721210540',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_197.jpg?v=1721210540',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_198.jpg?v=1721210542',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_199.jpg?v=1721210540',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_200.jpg?v=1721210595',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_201.jpg?v=1721210596',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_202.jpg?v=1721210595',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_203.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_204.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_205.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_206.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_207.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_208.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_209.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_210.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_211.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_212.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_213.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_214.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_215.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_216.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_217.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_218.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_219.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_220.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_221.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_222.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_223.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_224.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_225.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_226.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_227.jpg?v=1721210599',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_228.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_229.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_230.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_231.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_232.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_233.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_234.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_235.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_236.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_237.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_238.jpg?v=1721210599',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_239.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_240.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_241.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_242.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_243.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_244.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_245.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_246.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_247.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_248.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_249.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_250.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_251.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_252.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_253.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_254.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_255.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_256.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_257.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_258.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_259.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_260.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_261.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_262.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_263.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_264.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_265.jpg?v=1721210595',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_266.jpg?v=1721210596',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_267.jpg?v=1721210596',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_268.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_269.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_270.jpg?v=1721210597',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_271.jpg?v=1721210598',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_272.jpg?v=1721210600',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_273.jpg?v=1721210599',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_274.jpg?v=1721210599',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_275.jpg?v=1721210599',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_276.jpg?v=1721210600',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_277.jpg?v=1721210600',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_278.jpg?v=1721210600',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_279.jpg?v=1721210601',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_280.jpg?v=1721210601',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_281.jpg?v=1721210601',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_282.jpg?v=1721210603',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_283.jpg?v=1721210602',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_284.jpg?v=1721210602',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_285.jpg?v=1721210602',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_286.jpg?v=1721210603',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_287.jpg?v=1721210603',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_288.jpg?v=1721210603',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_289.jpg?v=1721210604',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_290.jpg?v=1721210604',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_291.jpg?v=1721210604',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_292.jpg?v=1721210604',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_293.jpg?v=1721210605',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_294.jpg?v=1721210605',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_295.jpg?v=1721210605',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_296.jpg?v=1721210605',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_297.jpg?v=1721210606',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_298.jpg?v=1721210606',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_299.jpg?v=1721210606',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_300.jpg?v=1721210607',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_301.jpg?v=1721210607',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_302.jpg?v=1721210607',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_303.jpg?v=1721210608',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_304.jpg?v=1721210608',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_305.jpg?v=1721210608',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_306.jpg?v=1721210608',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_307.jpg?v=1721210608',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_308.jpg?v=1721210609',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_309.jpg?v=1721210609',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_310.jpg?v=1721210609',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_311.jpg?v=1721210610',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_312.jpg?v=1721210610',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_313.jpg?v=1721210610',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_314.jpg?v=1721210610',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_315.jpg?v=1721210610',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_316.jpg?v=1721210611',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_317.jpg?v=1721210611',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_318.jpg?v=1721210611',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_319.jpg?v=1721210611',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_320.jpg?v=1721210611',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_321.jpg?v=1721210612',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_322.jpg?v=1721210612',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_323.jpg?v=1721210612',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_324.jpg?v=1721210612',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_325.jpg?v=1721210613',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_326.jpg?v=1721210612',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_327.jpg?v=1721210613',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_328.jpg?v=1721210613',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_329.jpg?v=1721210614',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_330.jpg?v=1721210613',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_331.jpg?v=1721210613',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_332.jpg?v=1721210614',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_333.jpg?v=1721210614',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_334.jpg?v=1721210614',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_335.jpg?v=1721210614',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_336.jpg?v=1721210614',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_337.jpg?v=1721210615',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_338.jpg?v=1721210615',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_339.jpg?v=1721210615',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_340.jpg?v=1721210615',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_341.jpg?v=1721210615',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_342.jpg?v=1721210616',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_343.jpg?v=1721210616',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_344.jpg?v=1721210616',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_345.jpg?v=1721210616',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_346.jpg?v=1721210617',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_347.jpg?v=1721210617',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_348.jpg?v=1721210617',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_349.jpg?v=1721210617',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_350.jpg?v=1721210617',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_351.jpg?v=1721210620',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_352.jpg?v=1721210618',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_353.jpg?v=1721210619',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_354.jpg?v=1721210618',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_355.jpg?v=1721210618',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_356.jpg?v=1721210619',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_357.jpg?v=1721210620',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_358.jpg?v=1721210619',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_359.jpg?v=1721210621',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_360.jpg?v=1721210621',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_361.jpg?v=1721210620',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_362.jpg?v=1721210621',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_363.jpg?v=1721210621',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_364.jpg?v=1721210622',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_365.jpg?v=1721210622',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_366.jpg?v=1721210623',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_367.jpg?v=1721210623',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_368.jpg?v=1721210623',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_369.jpg?v=1721210624',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_370.jpg?v=1721210623',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_371.jpg?v=1721210624',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_372.jpg?v=1721210624',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_373.jpg?v=1721210625',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_374.jpg?v=1721210625',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_375.jpg?v=1721210625',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_376.jpg?v=1721210625',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_377.jpg?v=1721210625',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_378.jpg?v=1721210626',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_379.jpg?v=1721210626',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_380.jpg?v=1721210626',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_381.jpg?v=1721210627',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_382.jpg?v=1721210627',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_383.jpg?v=1721210627',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_384.jpg?v=1721210627',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_385.jpg?v=1721210627',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_386.jpg?v=1721210627',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_387.jpg?v=1721210628',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_388.jpg?v=1721210628',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_389.jpg?v=1721210629',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_390.jpg?v=1721210629',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_391.jpg?v=1721210629',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_392.jpg?v=1721210629',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_393.jpg?v=1721210629',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_394.jpg?v=1721210630',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_395.jpg?v=1721210630',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_396.jpg?v=1721210630',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_397.jpg?v=1721210630',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_398.jpg?v=1721210630',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_399.jpg?v=1721210630',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_400.jpg?v=1721210691',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_401.jpg?v=1721210690',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_402.jpg?v=1721210690',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_403.jpg?v=1721210691',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_404.jpg?v=1721210691',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_405.jpg?v=1721210692',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_406.jpg?v=1721210692',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_407.jpg?v=1721210692',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_408.jpg?v=1721210693',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_409.jpg?v=1721210693',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_410.jpg?v=1721210694',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_411.jpg?v=1721210694',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_412.jpg?v=1721210694',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_413.jpg?v=1721210695',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_414.jpg?v=1721210695',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_415.jpg?v=1721210696',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_416.jpg?v=1721210696',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_417.jpg?v=1721210696',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_418.jpg?v=1721210696',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_419.jpg?v=1721210697',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_420.jpg?v=1721210698',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_421.jpg?v=1721210698',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_422.jpg?v=1721210698',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_423.jpg?v=1721210698',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_424.jpg?v=1721210699',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_425.jpg?v=1721210699',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_426.jpg?v=1721210700',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_427.jpg?v=1721210701',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_428.jpg?v=1721210701',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_429.jpg?v=1721210701',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_430.jpg?v=1721210701',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_431.jpg?v=1721210702',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_432.jpg?v=1721210703',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_433.jpg?v=1721210703',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_434.jpg?v=1721210703',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_435.jpg?v=1721210704',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_436.jpg?v=1721210704',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_437.jpg?v=1721210704',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_438.jpg?v=1721210705',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_439.jpg?v=1721210705',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_440.jpg?v=1721210705',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_441.jpg?v=1721210706',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_442.jpg?v=1721210706',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_443.jpg?v=1721210706',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_444.jpg?v=1721210706',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_445.jpg?v=1721210707',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_446.jpg?v=1721210707',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_447.jpg?v=1721210708',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_448.jpg?v=1721210708',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_449.jpg?v=1721210708',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_450.jpg?v=1721210708',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_451.jpg?v=1721210709',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_452.jpg?v=1721210709',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_453.jpg?v=1721210709',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_454.jpg?v=1721210710',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_455.jpg?v=1721210710',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_456.jpg?v=1721210710',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_457.jpg?v=1721210711',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_458.jpg?v=1721210711',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_459.jpg?v=1721210712',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_460.jpg?v=1721210712',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_461.jpg?v=1721210712',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_462.jpg?v=1721210712',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_463.jpg?v=1721210713',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_464.jpg?v=1721210713',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_465.jpg?v=1721210714',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_466.jpg?v=1721210714',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_467.jpg?v=1721210715',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_468.jpg?v=1721210715',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_469.jpg?v=1721210715',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_470.jpg?v=1721210715',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_471.jpg?v=1721210716',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_472.jpg?v=1721210716',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_473.jpg?v=1721210716',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_474.jpg?v=1721210716',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_475.jpg?v=1721210717',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_476.jpg?v=1721210717',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_477.jpg?v=1721210717',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_478.jpg?v=1721210718',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_479.jpg?v=1721210718',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_480.jpg?v=1721210718',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_481.jpg?v=1721210719',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_482.jpg?v=1721210719',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_483.jpg?v=1721210719',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_484.jpg?v=1721210719',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_485.jpg?v=1721210720',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_486.jpg?v=1721210720',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_487.jpg?v=1721210720',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_488.jpg?v=1721210720',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_489.jpg?v=1721210721',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_490.jpg?v=1721210721',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_491.jpg?v=1721210721',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_492.jpg?v=1721210721',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_493.jpg?v=1721210721',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_494.jpg?v=1721210722',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_495.jpg?v=1721210722',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_496.jpg?v=1721210722',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_497.jpg?v=1721210723',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_498.jpg?v=1721210723',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_499.jpg?v=1721210723',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_500.jpg?v=1721210724',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_501.jpg?v=1721210724',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_502.jpg?v=1721210724',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_503.jpg?v=1721210724',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_504.jpg?v=1721210725',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_505.jpg?v=1721210725',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_506.jpg?v=1721210725',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_507.jpg?v=1721210725',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_508.jpg?v=1721210725',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_509.jpg?v=1721210725',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_510.jpg?v=1721210726',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_511.jpg?v=1721210725',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_512.jpg?v=1721210726',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_513.jpg?v=1721210726',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_514.jpg?v=1721210727',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_515.jpg?v=1721210727',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_516.jpg?v=1721210727',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_517.jpg?v=1721210727',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_518.jpg?v=1721210728',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_519.jpg?v=1721210728',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_520.jpg?v=1721210728',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_521.jpg?v=1721210729',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_522.jpg?v=1721210729',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_523.jpg?v=1721210729',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_524.jpg?v=1721210730',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_525.jpg?v=1721210730',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_526.jpg?v=1721210730',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_527.jpg?v=1721210730',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_528.jpg?v=1721210731',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_529.jpg?v=1721210731',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_530.jpg?v=1721210731',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_531.jpg?v=1721210731',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_532.jpg?v=1721210732',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_533.jpg?v=1721210732',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_534.jpg?v=1721210732',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_535.jpg?v=1721210732',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_536.jpg?v=1721210733',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_537.jpg?v=1721210732',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_538.jpg?v=1721210733',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_539.jpg?v=1721210733',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_540.jpg?v=1721210733',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_541.jpg?v=1721210733',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_542.jpg?v=1721210734',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_543.jpg?v=1721210734',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_544.jpg?v=1721210734',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_545.jpg?v=1721210735',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_546.jpg?v=1721210735',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_547.jpg?v=1721210735',
    width: 2048,
    height: 1366,
  },
  {
    url: 'https://cdn.shopify.com/s/files/1/0774/1744/7748/files/Aerosleep_mattress_premium_animation_548.jpg?v=1721210736',
    width: 2048,
    height: 1366,
  },
];

const animatedImagesArrayMapping: AnimatedImagesArrayMap = new Map([
  ['aerosleepMattress', aerosleepMattress],
  ['aerosleepMattressPremium', aerosleepMattressPremium],
]);

export const getAnimatedImagesArrayByText = (text: AnimatedImageType): AnimatedImagesInterface[] => {
  return (text && animatedImagesArrayMapping.get(text)) || aerosleepMattress;
};
