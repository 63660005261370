'use client';
import { ContentProductGridInterface } from '@qlevr/shared/interfaces';
import { useCollectionFilters } from '@qlevr/shared/providers';
import { Suspense, useEffect, useMemo } from 'react';
import Container from '../container/container';
import Section from '../section/section';
import CollectionProductFilters from './collection-product-filters';
import ProductItem from './product-item';
import { useSearchParams } from 'next/navigation';
import { useTranslations } from 'next-intl';

export interface ContentProductGridProps extends ContentProductGridInterface {}

export function ContentProductGrid(props: ContentProductGridProps) {
  const t = useTranslations();
  const { filteredProducts, setSelectedFilters } = useCollectionFilters();
  const searchParams = useSearchParams();

  const filters = useMemo(() => {
    const filters: Record<string, string[]> = {};
    if (props.collection?.shopifyFilters) {
      props.collection.shopifyFilters.forEach((filter) => {
        filter.values.forEach((value) => {
          if (searchParams.get(value.id)) {
            const array = filters[filter.id] ?? [];
            filters[filter.id] = [...array, value.input];
          }
        });
      });
    }

    return filters;
  }, [props.collection.shopifyFilters, searchParams]);

  useEffect(() => {
    setSelectedFilters(filters);
  }, [filters]);

  return (
    <Section {...props.section}>
      <Container>
        <Suspense>
          <CollectionProductFilters collection={props.collection} />
          <div className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-4">
            {filteredProducts.length > 0 ? (
              filteredProducts.map((product, key) => (
                <div key={key} className="col-span-1">
                  <ProductItem product={product} quickAdd={props.quickAdd} />
                </div>
              ))
            ) : (
              <div className="col-span-full">{t('collection.filters.noProducts')}</div>
            )}
          </div>
        </Suspense>
      </Container>
    </Section>
  );
}

export default ContentProductGrid;
