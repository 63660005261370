import { RATIOS } from '@qlevr/shared/constants';
import {
  ImageInterface,
  KeyValueInterface,
  LinkedProductToRedirectInterface,
  MetaInterface,
  PathInterface,
  ProductInterface,
  ProductRecommendationsInterface,
  ProductReviewInterface,
} from '@qlevr/shared/interfaces';
import { ImageEdgeShopify, ProductEdgeShopify, ProductShopify } from '@qlevr/shared/schema';
import {
  fullPageSlug,
  ShopifyRichTextToHtml,
  removeForbiddenIdChars,
  splitTextToMaxCharacters,
} from '@qlevr/shared/utilities';
import isEmpty from 'lodash.isempty';
import { imageShopifyMapper } from '../image/image-shopify';
import { getMetafieldByKey } from '../metafield/get-metafield-by-key';
import { productMetatagsMapper } from '../metatags/product-metatags';
import { mapPremiumProductInfo } from './premiumProductInfo';
import { productAnalyticsMapper } from './product-analytics';
import { mapColorOverviewImage } from './product-color-overview';
import { mapProductColors } from './product-colors';
import { productCombinationsMapper } from './product-combination';
import { mapMetaFeaturedImage } from './product-featured-image';
import { getFirstAvailableVariant } from './product-first-available-variant';
import { mapMetaNavigationImage } from './product-navigation-image';
import { productOptionMapper } from './product-option';
import { productPriceMapper } from './product-price';
import { mapAgeGroups } from './product-size-chart';
import { mapProductSizeSwatches } from './product-sizes';
import { getProductSlug } from './product-slug';
import { mapProductSpecifications } from './product-specifications';
import { getProductTags } from './product-tags';
import { productVariantsMapper } from './product-variant';

export function metaProductsMapper(products: { [id: string]: ProductShopify }, ids: Set<string>): ProductInterface[] {
  return Array.from(ids).reduce((acc, id) => {
    const productShopify = products[removeForbiddenIdChars(id)];
    if (!productShopify) {
      return acc;
    }

    const product = productMapper({ item: productShopify, locale: '', productRecommendations: [] });
    if (product) {
      acc.push(product);
    }

    return acc;
  }, [] as ProductInterface[]);
}

export function getMetaProductsById(items: string[], meta: MetaInterface): ProductInterface[] {
  return items.reduce((acc, id) => {
    const product = meta.products?.find((product) => product.id === id);
    if (product) {
      acc.push(product);
    }

    return acc;
  }, [] as ProductInterface[]);
}

export function getMetaProductById(id: string, meta: MetaInterface): ProductInterface | null {
  const product = meta.products?.find((product) => product.id === id);
  if (!product) {
    return null;
  }

  return product;
}

export function productsMapper(items: ProductEdgeShopify[], isCollection: boolean = false): ProductInterface[] {
  if (!items || isEmpty(items)) {
    return [];
  }

  return items
    .filter((item) => item.node)
    .reduce((acc, { node }) => {
      const product = productMapper({ item: node, locale: '', productRecommendations: [], isCollection });
      if (product && product.availableForSale) {
        acc.push(product);
      }

      return acc;
    }, [] as ProductInterface[]);
}
interface ProductMapperInterface {
  item: ProductShopify;
  locale: string;
  productRecommendations?: ProductRecommendationsInterface[] | null;
  productPageHandle?: string;
  reviews?: ProductReviewInterface | null;
  localisation?: PathInterface[] | null;
  fullLocale?: string | null;
  isCollection?: boolean;
}

export function productMapper({
  item,
  locale,
  productRecommendations,
  productPageHandle,
  reviews,
  localisation,
  fullLocale,
  isCollection,
}: ProductMapperInterface): ProductInterface {
  const variants = productVariantsMapper(
    item?.variants?.edges,
    getProductSlug(item.handle),
    locale,
    item.title,
    item?.metafields,
    item.id,
    item.tags,
  );
  const images = mapProductImages(item?.images?.edges);
  const thumbnailImages = mapProductThumbnailImages(item?.images?.edges);
  const featuredImage = mapMetaFeaturedImage(item.metafields)?.[0]?.value ?? '';
  const navigationImage = mapMetaNavigationImage(item.metafields)?.[0]?.value ?? '';
  const featuredText =
    getMetafieldByKey('featured_text', item.metafields?.[0]) ?? splitTextToMaxCharacters(item.description, 120);
  const bundlePromotion = getMetafieldByKey('bundle_promotion', item.metafields?.[1]) ?? null;
  const canonicalUrl = locale && `${fullPageSlug(fullLocale || locale)}${getProductSlug(item.handle)}`;
  const isPremium = Boolean(
    item.metafields?.find((metafield) => metafield?.key === 'premium')?.value.toLowerCase() === 'true',
  );
  const hasGuidedBuyingFlow =
    item.metafields?.find((metafield) => metafield?.key === 'guided_buying_flow')?.value.toLocaleLowerCase() === 'true';
  const linkedProductToRedirect = item.metafields?.find(
    (metafield) => metafield?.key === 'linked_product',
  ) as LinkedProductToRedirectInterface;
  const discountPercentage =
    item.metafields?.find((metafield) => metafield?.key === 'discount_percentage')?.value ?? null;
  const productUsps =
    ShopifyRichTextToHtml(item.metafields?.find((metafield) => metafield?.key === 'usp_s')?.value ?? '') ?? null;
  const showAvailableSizes =
    item.metafields?.find((metafield) => metafield?.key === 'show_available_sizes')?.value.toLowerCase() === 'true';
  const sizeOptionsLayout =
    item.metafields?.find((metafield) => metafield?.key === 'product_size_layout')?.value.toLocaleLowerCase() ?? null;
  const colorOptionsLayout =
    item.metafields?.find((metafield) => metafield?.key === 'product_color_layout')?.value.toLocaleLowerCase() ?? null;

  const metafields: KeyValueInterface[] | null =
    (isCollection &&
      item.metafields
        ?.map((metafield) => {
          return {
            key: metafield?.key ?? '',
            value: metafield?.value ?? '',
          };
        })
        .filter((metafield) => metafield !== null)) ||
    [];
  return {
    ageGroups: mapAgeGroups(item.metafields) ?? [],
    productType: item.productType ?? null,
    availableForSale: item.availableForSale,
    bundlePromotion,
    colorOverviewImage: mapColorOverviewImage(item.metafields),
    colors: mapProductColors(item.metafields),
    combinations: productCombinationsMapper(item.title, variants),
    contextualImage: images?.[1] ?? null,
    compareAtPriceRange: productPriceMapper(item?.compareAtPriceRange),
    cta: null,
    featuredImage:
      imageShopifyMapper(featuredImage, {
        ratio: RATIOS.DEFAULT,
        altText: item.title,
        columnConfig: { xs: 1, lg: 2 },
      }) ?? images?.[0],
    navigationImage:
      imageShopifyMapper(navigationImage, {
        ratio: 'ratio_2x1',
        ratioLg: 'ratio_5x4',
        columnConfig: { xs: 2, lg: 4 },
      }) ??
      imageShopifyMapper(featuredImage, { ratio: 'ratio_2x1', ratioLg: 'ratio_5x4', columnConfig: { xs: 2, lg: 4 } }) ??
      images?.[0],
    featuredText,
    firstAvailableVariant: getFirstAvailableVariant(variants),
    id: item.id ?? null,
    images,
    thumbnailImages,
    metatags: productMetatagsMapper(item.seo, images?.[0], localisation ?? null, canonicalUrl),
    options: productOptionMapper(item.options),
    priceRange: productPriceMapper(item?.priceRange),
    productRecommendations: productRecommendations ?? [],
    productSpecifications: mapProductSpecifications(item.metafields),
    sizes: mapProductSizeSwatches(item.metafields),
    slug: getProductSlug(item.handle),
    reviewId: item.metafields?.find((metafield) => metafield?.key === 'magento_product_id')?.value ?? null,
    review: reviews ?? null,
    tags: getProductTags(item.tags ?? [], productPageHandle ?? ''),
    productTags: item.tags ?? [],
    text: item.descriptionHtml,
    title: item.title ?? null,
    variants,
    vendor: item.vendor ?? null,
    discountPercentage: discountPercentage ? parseFloat(discountPercentage) : null,
    isPremium,
    hasGuidedBuyingFlow,
    linkedProductToRedirect,
    premiumProductInformation: mapPremiumProductInfo(item.metafields),
    usps: productUsps,
    showAvailableSizes,
    sizeOptionsLayout,
    colorOptionsLayout,
    metafields: metafields,
  };
}

export function mapProductImages(images: ImageEdgeShopify[]): ImageInterface[] {
  return images?.reduce((acc, val) => {
    const image = imageShopifyMapper(val.node.url, {
      ratio: RATIOS.DEFAULT,
      altText: val.node.altText,
      columnConfig: { xs: 1, lg: 2 },
    });
    if (image) {
      acc.push(image);
    }
    return acc;
  }, [] as ImageInterface[]);
}

export function mapProductThumbnailImages(images: ImageEdgeShopify[]): ImageInterface[] {
  return images?.reduce((acc, val) => {
    const image = imageShopifyMapper(val.node.url, {
      ratio: RATIOS.DEFAULT,
      altText: val.node.altText,
      columnConfig: { xs: 18, lg: 18 },
      container: false,
    });
    if (image) {
      acc.push(image);
    }
    return acc;
  }, [] as ImageInterface[]);
}
