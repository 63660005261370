'use client';
import { LINK_STYLES } from '@qlevr/shared/constants';
import { CategoryInterface, SectionInterface } from '@qlevr/shared/interfaces';
import { useParams, useRouter } from 'next/navigation';
import Container from '../container/container';
import LinkRenderer from '../link/link-renderer';
import Section from '../section/section';
import { useTranslations } from 'next-intl';

export interface BlogFilterProps {
  section: SectionInterface;
  categories: CategoryInterface[] | null;
}

export function BlogFilter(props: BlogFilterProps) {
  const { locale } = useParams<{ locale: string }>();
  const t = useTranslations();
  const params = useParams();
  const router = useRouter();

  if (!props.categories) return null;

  const getLinkStyle = (category?: string) => {
    const isActive = params.filter === category;
    const isShowAll = !params.filter;

    if (!isActive) {
      return LINK_STYLES.filter;
    }

    if (isShowAll) {
      return LINK_STYLES.filterActive;
    }

    return LINK_STYLES.filterActive;
  };

  const getLink = (category: string) => {
    const isActive = params.filter === category;
    if (!isActive) {
      return `/${locale}/blog/category/${category}`;
    }

    return `/${locale}/blog`;
  };

  const handleCategoryClick = (e: React.MouseEvent<HTMLButtonElement>, categoryId?: string) => {
    e.preventDefault();

    if (!categoryId) {
      router.push(`/${locale}/blog`, { scroll: false });
      return;
    }

    router.push(getLink(categoryId), { scroll: false });
  };

  return (
    <Section {...props.section}>
      <Container>
        <div className="flex w-full flex-wrap gap-2">
          <LinkRenderer
            cta={{
              label: t('blog.filter.showAll'),
              as: 'button',
              linkStyle: getLinkStyle(),
              onClick: (e) => handleCategoryClick(e),
            }}
          />
          {props.categories.map((category, key) => (
            <LinkRenderer
              key={key}
              cta={{
                label: category.name,
                as: 'button',
                linkStyle: getLinkStyle(category.uuid),
                onClick: (e) => handleCategoryClick(e, category.uuid),
              }}
            />
          ))}
        </div>
      </Container>
    </Section>
  );
}
