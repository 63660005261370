import { AnalyticsInterface, CartLineItemInterface } from '@qlevr/shared/interfaces';
import { GetCartQueryShopify } from '@qlevr/shared/schema';

export function cartAnalyticsMapper(
  cart: GetCartQueryShopify['cart'],
  items: CartLineItemInterface[],
): AnalyticsInterface {
  return {
    event: 'dl_begin_checkout',
    ...(cart?.cost.totalAmount?.amount ? { total_price: cart.cost.totalAmount.amount } : { total_price: '' }),
    ...(cart?.cost.totalTaxAmount?.amount ? { total_tax: cart.cost.totalTaxAmount.amount } : { total_tax: '' }),
    items: items.map((item: CartLineItemInterface) => ({
      product_id: item.productId || '',
      variant_id: item.variantId || '',
      ...(item.sku ? { sku: item.sku } : { sku: '' }),
      name: item.title || '',
      ...(item.price?.amount ? { price: item.price.amount } : { price: '' }),
      ...(item.price?.currencyCode ? { currency: item.price.currencyCode } : { currency: '' }),
      quantity: item.quantity,
    })),
  };
}
