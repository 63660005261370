export const cartUpsellProducts = `
  id
  tags
  title
  handle
  vendor
  productType
  compareAtPriceRange {
    minVariantPrice {
      amount
      currencyCode
    }
    maxVariantPrice {
      amount
      currencyCode
    }
  }
  priceRange {
    minVariantPrice {
      amount
      currencyCode
    }
    maxVariantPrice {
      amount
      currencyCode
    }
  }
  images(first: 2) {
    edges {
      node {
        id
        altText
        url
      }
    }
  }
  metafields(identifiers: [{key: "featured_image", namespace: "custom"}, { key: "discount_percentage", namespace: "custom" }, { key: "bundle_promotion", namespace: "custom" }]) {
    key
    value
    reference {
      ... on MediaImage {
        id
        image {
          url
        }
      }
    }
  }
  variants(first: 1) {
    edges {
      node {
        id
        availableForSale
        currentlyNotInStock
        quantityAvailable
        sku
        product {
            vendor
            productType
        }
        compareAtPrice {
          amount
          currencyCode
        }
        price {
          amount
          currencyCode
        }
        selectedOptions {
          name
          value
        }
        image {
          id
          url
        }
      }
    }
  }
`;
