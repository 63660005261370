'use client';
import { BREAKPOINTS } from '@qlevr/shared/constants';
import { AnimatedImagesInterface, Content3dTechLayerInterface } from '@qlevr/shared/interfaces';
import { getAnimatedImagesArrayByText } from '@qlevr/shared/utilities';
import { useScroll, useTransform } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Container from '../../container/container';
import Section from '../../section/section';
import Title from '../../title/title';
import AnimatedImageCanvas from './animated-image-canvas';
import { calculateProgress } from './calculate-progress';

export interface Content3dTechLayerProps extends Content3dTechLayerInterface {}

export function Content3dTechLayer({ title, section, titleWithText, type }: Content3dTechLayerProps) {
  const getOptimizedImagesArray = (images: AnimatedImagesInterface[]): AnimatedImagesInterface[] => {
    if (images.length > 300) {
      return images.filter((_, index) => index % 2 === 0);
    }
    return images;
  };

  const originalImages = getAnimatedImagesArrayByText(type);
  const images = getOptimizedImagesArray(originalImages);
  const numFrames = images.length;
  const scrollHeight = numFrames * 20;
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const [progress, setProgress] = useState<number[]>(new Array(titleWithText?.length || 0).fill(0));
  const [mainSwiper, setMainSwiper] = useState<SwiperCore | null>(null);

  const { scrollY } = useScroll();
  const sectionTop = sectionRef.current?.offsetTop ?? 0;
  const sectionHeight = scrollHeight - (typeof window !== 'undefined' ? window.innerHeight : 0);
  const progressTransform = useTransform(scrollY, [sectionTop, sectionTop + sectionHeight], [0, 100]);

  const handleScrollDown = useCallback(
    (newProgress: number[]) => {
      requestAnimationFrame(() => {
        const currentIndex = newProgress.lastIndexOf(100);
        if (currentIndex !== -1 && currentIndex < titleWithText!.length - 1) {
          mainSwiper?.slideTo(currentIndex + 1, 600, false);
        }
      });
    },
    [mainSwiper, titleWithText],
  );

  const handleScrollUp = useCallback(
    (newProgress: number[]) => {
      requestAnimationFrame(() => {
        const currentIndex = newProgress.findIndex((item) => item === 0);
        if (currentIndex !== -1 && currentIndex > 0) {
          mainSwiper?.slideTo(currentIndex - 1, 600, false);
        }
      });
    },
    [mainSwiper],
  );

  useEffect(() => {
    const unsubscribe = progressTransform.on('change', (latest: number) => {
      const currentProgress = Math.min(latest, 100);
      const prevProgress = Math.min(progressTransform.getPrevious() || 0, 100);
      const newProgress =
        titleWithText?.map((_, index) => calculateProgress(currentProgress, index, titleWithText.length)) || [];

      setProgress(newProgress);
      if (currentProgress > prevProgress) {
        handleScrollDown(newProgress);
      } else if (currentProgress < prevProgress) {
        handleScrollUp(newProgress);
      }
    });

    return () => unsubscribe();
  }, [progressTransform, titleWithText, handleScrollDown, handleScrollUp]);

  if (images.length === 0) return null;

  const modifiedSection = {
    ...section,
    theme: {
      ...section.theme,
      editor: `${section.theme.editor} max-w-[640px] duration-300 relative z-20 container mx-auto w-full text-center translate-y-12 xl:translate-y-[60px]`,
    },
  };

  return (
    <Section {...section} className="w-full">
      <Container>
        <div
          ref={sectionRef}
          className={`py-20 ${section.theme.background} ${section.theme.color.text} relative`}
          style={{ height: `${scrollHeight + numFrames}px` }}
        >
          <div className="sticky top-0 flex h-[calc(100vh)] flex-col">
            {title && <Title {...title} section={modifiedSection} className="" />}
            <AnimatedImageCanvas
              scrollHeight={scrollHeight}
              numFrames={numFrames}
              width={images[0].width || 2048}
              height={images[0].height || 2048}
              sectionRef={sectionRef}
              images={images}
            />
            <div className="absolute left-1/2 top-full w-full -translate-x-1/2 xl:container">
              <div
                className={`relative -translate-y-[calc(100%_+_88px)] transition-all duration-300 ${progress[progress.length - 1] > 99 ? 'md:-translate-y-52' : 'md:-translate-y-36 md:hover:-translate-y-52'}`}
              >
                <Swiper
                  onSwiper={setMainSwiper}
                  className={`${section.theme.carousel}`}
                  spaceBetween={24}
                  slidesPerView={1}
                  breakpoints={{
                    [BREAKPOINTS.md]: {
                      slidesPerView: 3,
                    },
                    [BREAKPOINTS.xs]: {
                      slidesPerView: 1,
                    },
                  }}
                >
                  {titleWithText?.map((item, index) => (
                    <SwiperSlide key={index} className="">
                      <div key={index} className="">
                        <div className="space-y-4">
                          <div className={`w-full ${section.theme.progressBar.background} relative h-1 rounded-full`}>
                            <div
                              className={`${section.theme.progressBar.progress} absolute left-0 top-0 h-full max-w-full rounded-full`}
                              style={{ width: `${progress[index]}%` }}
                            ></div>
                          </div>
                          <div className="space-y-1">
                            <h3 className="text-lg font-bold">{item.title}</h3>
                            <div className="text-base font-light" dangerouslySetInnerHTML={{ __html: item.text }} />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
}
