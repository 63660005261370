'use client';

import { CollectionCardInterface } from '@qlevr/shared/interfaces';
import { useCollectionFilters } from '@qlevr/shared/providers';
import { useTranslations } from 'next-intl';
import { Suspense } from 'react';
import FiltersModal from '../collection-filter/filters-modal';
import ImageToggle from './product-toggle';

interface CollectionProductFiltersProps {
  collection: CollectionCardInterface;
}

export function CollectionProductFilters({ collection }: CollectionProductFiltersProps) {
  const t = useTranslations();
  const { filteredProducts } = useCollectionFilters();

  const countLabel =
    filteredProducts.length === 1 ? 'collection.filters.totalProducts.one' : 'collection.filters.totalProducts.other';

  return (
    <div className="flex flex-col-reverse justify-between gap-6 py-6 font-serif lg:flex-row lg:items-center">
      <div>
        <FiltersModal shopifyFilters={collection.shopifyFilters} />
      </div>
      <div className="text-sm font-normal lg:pr-8">
        <span>{t(`${countLabel}`, { total: filteredProducts.length })}</span>
      </div>
      <Suspense>
        <ImageToggle product={collection.products} />
      </Suspense>
    </div>
  );
}

export default CollectionProductFilters;
