import { ProductInterface } from '@qlevr/shared/interfaces';
import { escapeUrl } from './url/escape-url';
import { parseInput } from './parse-input';

interface GetFilteredProductsProps {
  selectedFilters: Record<string, string[]>;
  products: ProductInterface[];
}
export function getFilteredProducts({ selectedFilters, products }: GetFilteredProductsProps): ProductInterface[] {
  let filtered = products;
  Object.keys(selectedFilters).forEach((filterId) => {
    const filters = selectedFilters[filterId];
    if (filters.length === 0) return;

    filtered = filtered.filter((product) => {
      return filters.some((filterObj) => {
        const parsedFilter = parseInput(filterObj);

        let match = true;

        if (parsedFilter.productType) {
          match = match && product.productType === parsedFilter.productType;
        }
        if (parsedFilter.productMetafield) {
          match =
            match &&
            !!product.metafields?.some(
              (metafield) =>
                metafield.key === parsedFilter.productMetafield.key &&
                metafield.value === parsedFilter.productMetafield.value,
            );
        }
        if (parsedFilter.variantOption) {
          match =
            match &&
            !!product.options?.some(
              (option) =>
                option.name === parsedFilter.variantOption.name &&
                option.values.includes(escapeUrl(parsedFilter.variantOption.value)),
            );
        }
        if (parsedFilter.productVendor) {
          match = match && product.vendor === parsedFilter.productVendor;
        }
        if (parsedFilter.tag) {
          match = match && !!product.productTags?.includes(parsedFilter.tag);
        }
        if (parsedFilter.available !== undefined) {
          match = match && product.availableForSale === parsedFilter.available;
        }

        return match;
      });
    });
  });

  return filtered;
}
