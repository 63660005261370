export const collectionFields = `
  __typename
  id
  handle
  title
  description
  image {
    id
    url
  }
  products(first: 20) {
    edges {
      node {
        id
        title
        description
        descriptionHtml
        handle
        availableForSale
        vendor
        productType
        compareAtPriceRange {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        images(first: 2) {
          edges {
            node {
              id
              altText
              url
            }
          }
        }
        options(first: 1) {
          id
          name
          values
        }
        metafields(identifiers: [{ key: "magento_product_id", namespace: "custom" }]) {
          key
          value
        }
        variants(first: 200) {
          edges {
            node {
              id
              availableForSale
              currentlyNotInStock
              quantityAvailable
              compareAtPrice {
                amount
                currencyCode
              }
              price {
                amount
                currencyCode
              }
              selectedOptions {
                name
                value
              }
              image {
                id
                url
              }
              metafields(identifiers: [{ key: "default_selected", namespace: "custom" }]) {
                key
                value
              }
            }
          }
        }
      }
    }
  }
`;