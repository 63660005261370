import {
  LinkedMetaDataInterface,
  MappingConnectedDataFunctionInterface,
  ProductInterface,
} from '@qlevr/shared/interfaces';
import {
  BuyingFlowProductStoryblok,
  CompareProductStoryblok,
  FeaturedCollectionCarouselStoryblok,
  FeaturedCollectionStoryblok,
  FeaturedProductsStoryblok,
  PageStoryblok,
  ProductBundlerStoryblok,
  ProductVariantsStoryblok
} from '@qlevr/shared/schema';
import isEmpty from 'lodash.isempty';

const componentMapping = new Map<string, MappingConnectedDataFunctionInterface<unknown, void>>([
  ['blogs', (block, meta) => mapBlog(meta)],
  ['featuredCollection', (block, meta) => mapFeaturedCollections(block as FeaturedCollectionStoryblok, meta)],
  ['featuredCollectionCarousel', (block, meta) => mapFeaturedCollectionsCarousel(block as FeaturedCollectionCarouselStoryblok, meta)],
  ['featuredProducts', (block, meta) => mapFeaturedProducts(block as FeaturedProductsStoryblok, meta)],
  ['productVariants', (block, meta) => mapProductVariants(block as ProductVariantsStoryblok, meta)],
  ['productBundler', (block, meta) => mapProductBundler(block as ProductBundlerStoryblok, meta)],
  ['guidedBuyingFlow', (block, meta) => mapGuidedBuyingFlowProducts(block as BuyingFlowProductStoryblok, meta)],
  ['productsComparison', (block, meta) => mapComparisonProducts(block as CompareProductStoryblok, meta)],
  ['instagram', (block, meta) => mapInstagram(meta)],
]);

export function getContentBlocksLinkedData(
  contentBlocks: PageStoryblok['contentBlocks'],
  meta: LinkedMetaDataInterface,
): LinkedMetaDataInterface {
  if (!contentBlocks || isEmpty(contentBlocks)) {
    return meta;
  }

  for (const block of contentBlocks) {
    if (!block.component) {
      throw new Error('Content block cannot be mapped because component is undefined.');
    }

    const component = componentMapping.get(block.component);
    if (!component) {
      continue;
    }

    component(block, meta);
  }

  return meta;
}

function mapBlog(meta: LinkedMetaDataInterface) {
  meta.blog = true;
}

function mapInstagram(meta: LinkedMetaDataInterface) {
  meta.instagram = true;
}

function mapFeaturedProducts(block: FeaturedProductsStoryblok, meta: LinkedMetaDataInterface) {
  block['products'].items.forEach((item: { id: string }) => {
    meta.products.add(item.id);
    meta.productReviewIds.add(item.id);
  });
}

function mapFeaturedCollections(block: FeaturedCollectionStoryblok, meta: LinkedMetaDataInterface) {
  block['collections'].items.forEach((item: { id: string }) => {
    meta.collections.add(item.id);
  });
}

function mapFeaturedCollectionsCarousel(block: FeaturedCollectionCarouselStoryblok, meta: LinkedMetaDataInterface) {
  block['collections'].items.forEach((item: { id: string }) => {
    meta.collections.add(item.id);
  });
}

function mapProductVariants(block: ProductVariantsStoryblok, meta: LinkedMetaDataInterface) {
  block['product'].items.forEach((item: { id: string }) => {
    meta.products.add(item.id);
    meta.productReviewIds.add(item.id);
  });
}

function mapProductBundler(block: ProductBundlerStoryblok, meta: LinkedMetaDataInterface) {
  block?.['product'].items.forEach((item: { id: string }) => {
    meta.products.add(item.id);
    meta.productReviewIds.add(item.id);
  });

  block?.['products'].items.forEach((item: { id: string }) => {
    meta.products.add(item.id);
    meta.productReviewIds.add(item.id);
  });
}

function mapGuidedBuyingFlowProducts(block: BuyingFlowProductStoryblok, meta: LinkedMetaDataInterface) {
  const guidedBuyingFlowMainProduct = block?.['product'].items[0] as ProductInterface;
  const buyingFlowProducts = block?.['products'];

  // add guidedBuyingFlowMainProduct to meta
  if (guidedBuyingFlowMainProduct?.id) {
    meta.products.add(guidedBuyingFlowMainProduct.id);
    meta.productReviewIds.add(guidedBuyingFlowMainProduct.id);
  }

  if (!Array.isArray(buyingFlowProducts)) {
    return;
  }

  buyingFlowProducts.forEach((productBlock) => {
    // Check if productBlock and product.items exist and is an array
    if (!productBlock || !productBlock.product || !Array.isArray(productBlock.product.items)) {
      return;
    }

    // Extract items from product
    const productItems = productBlock.product.items;

    productItems.forEach((item: ProductInterface) => {
      if (item?.id) {
        meta.products.add(item.id);
        meta.productReviewIds.add(item.id);
      } else {
        console.log('Undefined product ID encountered.');
      }
    });
  });
}

function mapComparisonProducts(block: CompareProductStoryblok, meta: LinkedMetaDataInterface) {
  // Check if block and products array exist and is an array
  const productsArray = block?.['products'];

  if (!Array.isArray(productsArray)) {
    return;
  }

  productsArray.forEach((productBlock) => {
    // Check if productBlock and product.items exist and is an array
    if (!productBlock || !productBlock.product || !Array.isArray(productBlock.product.items)) {
      return;
    }

    // Extract items from product
    const productItems = productBlock.product.items;

    productItems.forEach((item: ProductInterface) => {
      if (item?.id) {
        meta.products.add(item.id);
        meta.productReviewIds.add(item.id);
      } else {
        console.log('Undefined product ID encountered.');
      }
    });
  });
}
