import { LOCALES } from '@qlevr/shared/constants';
import { FetchStoryblokStoriesInteface, MetaInterface } from '@qlevr/shared/interfaces';
import { getLocaleFromCountryLocale, hasFallbackLocale } from '@qlevr/shared/utilities';
import isEmpty from 'lodash.isempty';
import { notFound } from 'next/navigation';
import { resolveRelationsQueryParams } from '../resolve-relations';

export abstract class FetchStoryblokStoriesService<T, S> {
  private params: FetchStoryblokStoriesInteface;

  constructor(params: FetchStoryblokStoriesInteface) {
    this.params = params;
    this.params.locale = this.params.locale.toLocaleLowerCase() ?? LOCALES[0];
  }

  protected abstract mapper(data: S[], meta: MetaInterface): T[];

  public async request(meta: MetaInterface): Promise<T[]> {
    let url = `https://api.storyblok.com/v2/cdn/stories?${this.getParams}`;
    // cache invalidation
    let res = await fetch(url, { next: { tags: [this.params.pageType]}});
    let isError = res.status !== 200;

    if (isError && !hasFallbackLocale(this.params.locale)) {
      console.error('Page not found', url);
      return notFound();
    }

    // Retry with the fallback locale
    if (isError && hasFallbackLocale(this.params.locale)) {
      url = `https://api.storyblok.com/v2/cdn/stories?${this.fallBackParams}`;
      res = await fetch(url);
      isError = res.status !== 200;
    }

    if (isError) {
      console.error('Page not found', url);
      return notFound();
    }

    let page = await res.json();

    // no data in the current locale, try the fallback locale
    const hasStories = page.stories && !isEmpty(page.stories);
    if (!hasStories && hasFallbackLocale(this.params.locale)) {
      console.error('No stories found in this locale', this.params.locale, url);
      url = `https://api.storyblok.com/v2/cdn/stories?${this.fallBackParams}`;
      res = await fetch(url);
      page = await res.json();
      isError = res.status !== 200;
    }

    if (isError) {
      console.error('Page not found', url);
      return notFound();
    }

    if (!meta.rels) {
      meta.rels = page.rels
    } else {
      Object.assign(meta.rels, page.rels);
    }

    return this.mapper(page.stories, meta);
  }

  private get getParams(): string {
    return new URLSearchParams({
      starts_with: `${this.params.locale}/${process.env['NEXT_PUBLIC_BRAND_NAME']}${this.params.pageType}`,
      is_startpage: '0',
      token: process.env['NEXT_PUBLIC_STORYBLOK_API_TOKEN'] as string,
      version: this.params.preview ? 'draft' : 'published',
      resolve_relations: resolveRelationsQueryParams,
      sort_by: 'created_at:desc',
      ...(this.params.filter ? this.params.filter.reduce((acc, { key, value }) => {
        acc[key] = value;
        return acc;
      }, {}) : {})
    }).toString();
  }

  private get fallBackParams(): string {
    return new URLSearchParams({
      starts_with: `${getLocaleFromCountryLocale(this.params.locale)}/${process.env['NEXT_PUBLIC_BRAND_NAME']}${this.params.pageType}`,
      is_startpage: '0',
      token: process.env['NEXT_PUBLIC_STORYBLOK_API_TOKEN'] as string,
      version: this.params.preview ? 'draft' : 'published',
      resolve_relations: resolveRelationsQueryParams,
      sort_by: 'created_at:desc',
    }).toString();
  }
}
