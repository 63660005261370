'use client';
import { ContentTextVideoOverlayInterface } from '@qlevr/shared/interfaces';
import Container from '../../container/container';
import LinksRenderer from '../../link/links-renderer';
import Section from '../../section/section';
import Text from '../../text/text';
import Title from '../../title/title';
import VideoClientWrapper from '../../video-player/video-client-wrapper';

export function ContentTextVideoOverlay({
  alignment,
  cta,
  overlay,
  section,
  text,
  title,
  video,
}: ContentTextVideoOverlayInterface) {
  return (
    <Section {...section}>
      <div className={`relative ${video.ratio} ${video.ratioLg}`}>
        <VideoClientWrapper video={video} overlay={overlay} title={title} text={text} />
        {(title || text) && (
          <div className={`absolute left-0 top-0 h-full w-full`}>
            <div className={`relative h-full py-10 sm:py-20 lg:py-28`}>
              <Container className="h-full">
                <div className={`flex h-full ${alignment.vertical} ${alignment.horizontal}`}>
                  <div className={`space-y-4 lg:w-1/2 lg:space-y-6 ${alignment.text}`}>
                    {title && <Title {...title} section={section} />}
                    {text && <Text text={text} theme={section.theme} />}
                    {cta && <LinksRenderer className={`flex gap-4 ${alignment.text}`} cta={cta} />}
                  </div>
                </div>
              </Container>
            </div>
          </div>
        )}
      </div>
    </Section>
  );
}

export default ContentTextVideoOverlay;
