'use client';
import { TitleInterface, VideoInterface } from '@qlevr/shared/interfaces';
import VideoPlayer from './video-player';
import VideoStretchedPlayPause from './video-stretched-playpause';

interface VideoClientWrapperProps {
  video: VideoInterface;
  overlay?: boolean;
  title?: TitleInterface | null;
  text?: string | null;
}

export function VideoClientWrapper({ video, overlay, title, text }: VideoClientWrapperProps) {
  let isStretchedVideo;

  if (!title && !text) {
    isStretchedVideo = VideoStretchedPlayPause;
  }

  return <VideoPlayer video={video} overlay={overlay} playPauseComponent={isStretchedVideo} />;
}

export default VideoClientWrapper;
