'use client';

import { ImageInterface, ProductInterface, VariantImagesInterface } from '@qlevr/shared/interfaces';
import { getCombinationBySizeAndOrColor } from '@qlevr/shared/utilities';
import isEmpty from 'lodash.isempty';
import { useQueryState } from 'nuqs';
import { useEffect, useState } from 'react';
import ProductCarousel from './product-carousel';

export interface ProductVariantCarouselProps {
  product: ProductInterface;
}

const getImages = (
  variantImages?: VariantImagesInterface[] | null,
): { images: ImageInterface[]; thumbnailImages: ImageInterface[] } => {
  const images: ImageInterface[] = [];
  const thumbnailImages: ImageInterface[] = [];

  variantImages?.forEach((image) => {
    if (image.image) {
      images.push(image.image);
    }
    if (image.thumbnail) {
      thumbnailImages.push(image.thumbnail);
    }
  });

  return { images, thumbnailImages };
};

export function ProductVariantImages({ product }: ProductVariantCarouselProps) {
  const [activeImages, setImages] = useState<ImageInterface[]>([]);
  const [thumbnailImages, setThumbnailImages] = useState<ImageInterface[]>([]);
  const [size, ] = useQueryState('size');
  const [color, ] = useQueryState('color');

  useEffect(() => {
    // loop through the combinations and find the matched variant
    const matchedVariant = getCombinationBySizeAndOrColor(product.combinations, size, color);
    const firstAvailableVariantImages = product.firstAvailableVariant?.images;
    // use noVariantImages if matchedVariant.variantImages is not available
    const variantImages = matchedVariant?.variantImages || firstAvailableVariantImages;
    const { images, thumbnailImages } = getImages(variantImages);

    // if there is no matched variant or neither size nor color is specified, set the default images
    if (!matchedVariant || (!size && !color)) {
      setImages(images);
      setThumbnailImages([]);
      return;
    }

    // check if the variant has images
    const hasVariantImages = images && !isEmpty(images);
    if (!hasVariantImages) {
      setImages(product.images);
      setThumbnailImages([]);
      return;
    }

    // set the images of the matched variant
    setImages(images);
    // set the thumbnail images of the matched variant
    if (thumbnailImages && !isEmpty(thumbnailImages)) {
      setThumbnailImages(thumbnailImages);
    }
  }, [product.combinations, product.firstAvailableVariant?.images, product.images, size, color]);

  return (
    <div className="col-span-1 aspect-square h-fit lg:sticky lg:top-3 lg:col-span-6 lg:aspect-auto">
      <ProductCarousel images={activeImages} thumbnailImages={thumbnailImages} featuredImage={product.firstAvailableVariant?.images?.[0]} />
    </div>
  );
}

export default ProductVariantImages;
