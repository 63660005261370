'use client';

import { BUTTON_SIZE, LanguageType, usePathname, useRouter } from '@qlevr/shared/constants';
import { PathInterface } from '@qlevr/shared/interfaces';
import { getLocaleLabel } from '@qlevr/shared/utilities';
import isEmpty from 'lodash.isempty';
import { useParams } from 'next/navigation';
import LinkRenderer from '../link/link-renderer';

export interface InternationalisationDropdownProps {
  localisation: PathInterface[] | null;
}

export function MenuDrawerInternationalisation({ localisation }: InternationalisationDropdownProps) {
  const { locale } = useParams<{ locale: string }>();
  const router = useRouter();
  const pathName = usePathname();

  const localisationWithCurrentPath = [...(localisation || []), { locale, slug: pathName }]; // Add current path to the list of localisations

  if (!localisation || isEmpty(localisation)) {
    return null;
  }

  const switchLocale = (path: PathInterface) => {
    router.push(path.slug, { locale: path.locale as LanguageType | undefined });
  };

  return (
    <div className="mb-4 flex flex-wrap gap-4 border-b border-solid border-slate-300 py-4">
      {localisationWithCurrentPath.map((path, key) => (
        <div key={key}>
          <LinkRenderer
            cta={{
              label: getLocaleLabel(path.locale),
              as: 'button',
              linkStyle: 'text-black',
              size: BUTTON_SIZE.base,
              onClick: () => switchLocale(path),
              className: `${locale === path.locale ? 'underline' : ''}`,
            }}
          />
        </div>
      ))}
    </div>
  );
}

export default MenuDrawerInternationalisation;
