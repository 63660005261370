import { MetaInterface, ProductInterface, TextImageCardHeroInterface } from '@qlevr/shared/interfaces';
import { HeroTextImageCardStoryblok } from '@qlevr/shared/schema';
import { imageStoryblokMapper } from '../image/image-storyblok';
import { linksMapper } from '../link/link';
import { mapRichText } from '../rich-text/rich-text';
import { sectionMapper } from '../section/section';
import { getMetaProductById } from '../products/products-shopify';

export function mapTextImageCardHero(title: string, content: HeroTextImageCardStoryblok, meta: MetaInterface): TextImageCardHeroInterface | null {
  const productId = content['product']?.items?.[0]?.id;

  let product = {} as ProductInterface | null;
  if(productId) {
    product = getMetaProductById(productId, meta);
  }
  
  const image = imageStoryblokMapper(content.image?.[0], {
    xs: 1,
    lg: 3,
  });

  if(!image) {
    return null;
  }

  return {
    _editable: content['_editable'] ?? null,
    _uid: content._uid,
    component: content.component,
    title: content.title ?? title,
    rating: content.rating ?? false,
    text: mapRichText(content.text),
    cta: linksMapper(content.cta),
    image,
    section: sectionMapper(content.section?.[0]),
    reviews: product?.review ?? null,
  };
}
