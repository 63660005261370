import { getSdk } from '@qlevr/shared/schema';
import { GraphQLClient } from 'graphql-request';

const endpoint = process.env['NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ENDPOINT'] as string;
const accessToken = process.env['NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN'] as string;


export interface ShopifyCartServiceOptions {
  tags?: string[];
  cache?: RequestCache;
  revalidate?: number;
}

export const shopifyClient = ({ cache, tags, revalidate }: ShopifyCartServiceOptions) => new GraphQLClient(endpoint, {
  fetch,
  headers: {
    'X-Shopify-Storefront-Access-Token': accessToken,
  },
  cache,
  next: {
    ...(tags ? { tags } : {}),
    ...(revalidate ? { revalidate } : {}),
  }
});

export function ShopifyService({ cache, tags, revalidate }: ShopifyCartServiceOptions) {
  const client = new GraphQLClient(endpoint, {
    fetch,
    headers: {
      'X-Shopify-Storefront-Access-Token': accessToken,
    },
    cache,
    next: {
      ...(tags ? { tags } : {}),
      ...(revalidate ? { revalidate } : {}),
    }
  });

  return getSdk(client);
}
