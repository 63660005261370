import { FilterValueShopify } from '@qlevr/shared/schema';

interface ToggleSwitchFilterProps {
  checked: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isDisabled: boolean;
  option: FilterValueShopify;
}

export function ToggleSwitchFilter({ checked, handleChange, isDisabled, option }: ToggleSwitchFilterProps) {

  if (option.label.toLocaleLowerCase() === 'no') {
    return null;
  }
  
  return (
    <div className="flex items-center gap-2">
      <label className="inline-flex cursor-pointer items-center">
        <input
          id={option.id}
          type="checkbox"
          className="peer sr-only"
          checked={checked}
          onChange={handleChange}
          value={option.input}
          disabled={isDisabled}
        />
        <div
          className={`${checked ? 't-toggle-enabled-bg' : 't-toggle-disabled-bg'} peer relative h-8 w-16 rounded-full after:absolute after:left-1 after:top-1 after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:after:translate-x-8 peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 peer-disabled:cursor-not-allowed peer-disabled:opacity-60`}
        ></div>
      </label>
    </div>
  );
}
