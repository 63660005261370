'use client';
import { BREAKPOINTS, BUTTON_SIZE, LINK_STYLES, LinkStyleType } from '@qlevr/shared/constants';
import { ContentFeaturedCollectionInterface } from '@qlevr/shared/interfaces';
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Container from '../container/container';
import ImageRenderer from '../image-renderer/image-renderer';
import LinkRenderer from '../link/link-renderer';
import ProductCardVariants from '../product-grid/product-card-variants';
import ProductBuy from '../product-status/product-buy';
import StarRating from '../rating/star-rating';
import Section from '../section/section';
import Text from '../text/text';
import Title from '../title/title';

/* eslint-disable-next-line */
export interface ContentFeaturedCollectionProps extends ContentFeaturedCollectionInterface {}

export function ContentFeaturedCollection({
  alignment,
  collections,
  section,
  title,
  text,
}: ContentFeaturedCollectionProps) {
  const buttonMapping = new Map<string, LinkStyleType>([
    ['t-light-bg', LINK_STYLES.primaryOutline],
    ['t-warm-bg', LINK_STYLES.primaryOutline],
    ['t-cold-bg', LINK_STYLES.primaryOutline],
    ['t-dark-bg', LINK_STYLES.tertiaryOutline],
  ]);

  return (
    <Section {...section}>
      <Container>
        <div className="space-y-6 lg:space-y-10">
          {!!(title || text) && (
            <div className={`flex ${alignment.horizontal}`}>
              <div className={`lg:w-1/2 ${alignment.text}`}>
                {title && <Title {...title} section={section} />}
                {text && <Text text={text} theme={section.theme} />}
              </div>
            </div>
          )}

          <Swiper
            className={`swiper-with-nav swiper-with-scrollbar ${section.theme.carousel}`}
            spaceBetween={24}
            slidesPerView={4}
            scrollbar={{ draggable: true, dragClass: `swiper-scrollbar-drag` }}
            navigation={true}
            modules={[Scrollbar, Navigation]}
            breakpoints={{
              [BREAKPOINTS.lg]: {
                slidesPerView: 4,
              },
              [BREAKPOINTS.sm]: {
                slidesPerView: 3,
                spaceBetween: 16,
              },
              [BREAKPOINTS.xs]: {
                slidesPerView: 1.5,
              },
            }}
          >
            {collections?.map((item, key) => (
              <div key={key}>
                {item.products?.map((product, key) => {
                  const sortedVariants = product.variants?.sort(
                    (a, b) => Number(b.defaultSelected) - Number(a.defaultSelected),
                  );
                  const defaultVariant = sortedVariants?.[0];

                  return (
                    <SwiperSlide key={key}>
                      <div className="relative">
                        <Swiper spaceBetween={10} pagination={{ clickable: true }} modules={[Pagination]} loop={false}>
                          {product.featuredImage && (
                            <SwiperSlide key="productFeaturedImage">
                              <div className="rounded-lg bg-white">
                                <ImageRenderer
                                  loadingState="lazy"
                                  image={product.featuredImage}
                                  className="aspect-square rounded-lg bg-white object-contain"
                                />
                              </div>
                            </SwiperSlide>
                          )}
                          {product.contextualImage && (
                            <SwiperSlide key="productSecondaryImage">
                              <div className="rounded-lg bg-white">
                                <ImageRenderer
                                  loadingState="lazy"
                                  image={product.contextualImage}
                                  className="aspect-square rounded-lg bg-white object-contain"
                                />
                              </div>
                            </SwiperSlide>
                          )}
                        </Swiper>
                      </div>
                      <div className={`flex flex-col py-3 ${section.theme.color.heading}`}>
                        <LinkRenderer
                          cta={{
                            label: `${product.title}`,
                            as: 'link',
                            href: product.slug,
                            linkStyle: LINK_STYLES.textBlack,
                            className: `text-base font-bold pb-1 ${section.theme.color.heading}`,
                          }}
                        />
                        {product.showAvailableSizes && <ProductCardVariants sortedVariants={sortedVariants} />}
                        {product.review?.totalReview && (
                          <StarRating className="pb-1" section={section} reviews={product.review?.totalReview} />
                        )}
                        <div>
                          {defaultVariant?.compareAtPrice && (
                            <span className="mr-1 text-slate-400 line-through">
                              {defaultVariant?.compareAtPrice.symbol}
                              {defaultVariant?.compareAtPrice.amount}
                            </span>
                          )}
                          <span>
                            {defaultVariant?.price?.symbol}
                            {defaultVariant?.price?.amount}
                          </span>
                        </div>
                      </div>
                      {defaultVariant?.id && (
                        <ProductBuy
                          cta={{
                            linkStyle: buttonMapping.get(section.theme.background) || LINK_STYLES.primaryOutline,
                            size: BUTTON_SIZE.small,
                            className: `${section.theme.color.heading} w-full`,
                          }}
                          variantId={defaultVariant.id}
                          product={product}
                        />
                      )}
                    </SwiperSlide>
                  );
                })}
              </div>
            ))}
          </Swiper>
        </div>
      </Container>
    </Section>
  );
}

export default ContentFeaturedCollection;
