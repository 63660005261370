import { BREAKPOINTS } from '@qlevr/shared/constants';
import { ColumnConfigInterface, ImageInterface } from '@qlevr/shared/interfaces';
import { getImageColumnConfigDesktop, getImageColumnConfigMobile, imageSizesGenerator } from '@qlevr/shared/utilities';
import { ImageMapperStrategyResolver } from './image-mapper-strategy-resolver';
import { ratioMapper } from '@qlevr/shared/mappers';
export interface ImageRendererProps {
  image: ImageInterface | null;
  className?: string;
  sizes?: string;
  loadingState?: 'lazy' | 'eager';
  fetchPriority?: 'auto' | 'high' | 'low';
}
export function ImageRenderer({ image, loadingState, className = '', fetchPriority = 'auto' }: ImageRendererProps) {
  if (!image) {
    return null;
  }

  const columnConfigMobile: ColumnConfigInterface = getImageColumnConfigMobile(image.columnConfig);
  const columnConfigDesktop: ColumnConfigInterface = getImageColumnConfigDesktop(image.columnConfig);

  const mapper = new ImageMapperStrategyResolver().getImageStrategy(image);

  const url = mapper.srcGenerator(image.url, image.ratio, image.columnConfig);
  const srcSet = mapper.srcSetGenerator(image.url, image.ratio, image.columnConfig);
  const sizes = imageSizesGenerator(image.columnConfig, image.container);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const srcSetXs = mapper.srcSetGenerator(image.url, image.ratio, columnConfigMobile);
  const sizesXs = imageSizesGenerator(columnConfigMobile, image.container);

  const srcSetLg = mapper.srcSetGenerator(image.lg.url, image.lg.ratio, image.columnConfig);
  const sizesLg = imageSizesGenerator(columnConfigDesktop, image.container);

  const ratio = ratioMapper(image.ratio);
  const ratioLg = ratioMapper(image.lg.ratio);

  return (
    <picture className={`block ${ratio} ${ratioLg} ${className}`}>
      <source
        media={`(max-width: ${BREAKPOINTS.lg - 1}px)`}
        {...(srcSet ? { srcSet: srcSet } : {})}
        {...(sizesXs ? { sizes: sizesXs } : {})}
      />
      <source
        media={`(min-width: ${BREAKPOINTS.lg}px)`}
        {...(srcSetLg ? { srcSet: srcSetLg } : {})}
        {...(sizesLg ? { sizes: sizesLg } : {})}
      />
      <img
        alt={image.alt}
        className={`h-full w-full object-cover ${className}`}
        src={url}
        {...(srcSet ? { srcSet: srcSet } : {})}
        {...(sizes ? { sizes: sizes } : {})}
        loading={loadingState}
        fetchPriority={fetchPriority}
      />
    </picture>
  );
}
export default ImageRenderer;
