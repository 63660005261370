'use client';
import { ContentTypeFormInterface, TypeformResponseData } from '@qlevr/shared/interfaces';
import Script from 'next/script';
import Section from '../section/section';
import { useEffect } from 'react';
import { getCookie, setCookie } from '@qlevr/shared/utilities';

/* eslint-disable-next-line */
export interface ContentTypeFormProps extends ContentTypeFormInterface {}

// Define the onTypeformSubmit function
function onTypeformSubmit(data: TypeformResponseData) {
  // Get the existing response IDs from the cookie
  const existingResponses = getCookie('typeFormIds');
  const responseIds = existingResponses ? JSON.parse(existingResponses) : [];

  // Append the new response ID
  responseIds.push(data.responseId);

  // Save the updated array back to the cookie
  setCookie('typeFormIds', JSON.stringify(responseIds), 30); // Expires in 30 days
}

export function ContentTypeForm(props: ContentTypeFormProps) {
  useEffect(() => {
    // Attach the function to the window object
    window.onTypeformSubmit = onTypeformSubmit;

    // Cleanup function to remove the attached function
    return () => {
      delete window.onTypeformSubmit;
    };
  }, []);

  if (!props.typeFormId) {
    return null;
  }

  return (
    <Section {...props.section}>
      <div data-tf-live={props.typeFormId} data-tf-on-submit="onTypeformSubmit"></div>
      <Script src="//embed.typeform.com/next/embed.js" />
    </Section>
  );
}

export default ContentTypeForm;
