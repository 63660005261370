import { useCart } from '@qlevr/shared/providers';
import { setAuthCookie } from '../cookies/cookies';

export function useLogin() {
  const cartContext = useCart();

  const loginFormSubmit = async (
    email: string,
    password: string,
    onSuccess: () => void,
    onError: (error: string) => void,
    locale: string
  ) => {
    try {
      const customerData = {
        email,
      };

      try {
        const response = await fetch(`/${locale}/api/multipass`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ customerData, locale }),
        });

        try {
          cartContext.login(email, password);

          await setAuthCookie(true);
        } catch (e) {
          console.error(e);
        }

        const { url } = await response.json();
        window.open(url, '_blank');
      } catch (error) {
        console.error(error);
      }

      onSuccess();
    } catch (error) {
      onError(`Something went wrong ${error}`);
    }
  };

  return { loginFormSubmit };
}

export default useLogin;
