import { ColorThemeType } from '@qlevr/shared/constants';
import { AnalyticsInterface } from '../analytics/analytics';
import { TextImageCardInterface } from '../card/text-image-card';
import { ImageInterface } from '../image/image';
import { ProductVariantOptionInterface } from '../product/product-variant';
import { PriceVariantInterface } from '../product/product-variant-price';
import { CartUpsellProducts } from './cart-upsell';

export interface CartInterface {
  id: string;
  checkoutUrl: string;
  cost: CartCostInterface;
  items: CartLineItemInterface[];
  totalQuantity: number;
  savings: string | null;
  freeShipping: CartFreeShippingInterface | null;
  upsellProducts: CartUpsellProducts[] | null;
  analytics: AnalyticsInterface | null;
  pending?: boolean;
}

export function isCartInterface(obj: any): obj is CartInterface {
  return obj && typeof obj === 'object' && 'id' in obj && 'checkoutUrl' in obj;
}

export interface CartLineItemInterface {
  id: string;
  sku: string | null;
  slug: string;
  title: string;
  vendor: string | null;
  productType?: string | null;
  featuredImage: ImageInterface | null;
  variantId: string;
  productId: string;
  productBundlePromotion: string | null;
  quantity: number;
  price: PriceVariantInterface | null;
  compareAtPrice: PriceVariantInterface | null;
  variantCompareAtAmountPerQuantity: PriceVariantInterface | null;
  currencyCode: string | null;
  options: ProductVariantOptionInterface[];
  discounts: CartAutomaticDiscountInterface[] | null;
  productTags: string[] | null;
  shippingDate: string | null;
}

export interface CartCostInterface {
  subtotalAmount: PriceVariantInterface | null;
  totalAmount: PriceVariantInterface | null;
  totalTaxAmount: PriceVariantInterface | null;
  currencyCode: string;
}

export interface CartAutomaticDiscountInterface {
  title: string | null;
}

export interface CartFreeShippingInterface {
  threshold: { [x: string]: number } | null;
}

export interface EmptyCartInterface {
  title: string | null;
  text: string;
  emptyCartProduct: TextImageCardInterface | null;
  emptyCartProductId: string | null;
  theme: ColorThemeType;
}
