'use client';
import { BUTTON_SIZE, LINK_STYLES } from '@qlevr/shared/constants';
import { ProductInterface } from '@qlevr/shared/interfaces';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import SwiperCore from 'swiper';
import { EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import ImageRenderer from '../image-renderer/image-renderer';
import LinkRenderer from '../link/link-renderer';
import ProductBuy from '../product-status/product-buy';
import ProductCardVariants from './product-card-variants';
import { escapeUrl } from '@qlevr/shared/utilities';

SwiperCore.use([EffectFade]);
interface ProductItemProps {
  product: ProductInterface;
  quickAdd: boolean;
}

function ProductItem({ product, quickAdd }: ProductItemProps) {
  const searchParams = useSearchParams();
  const params = new URLSearchParams(searchParams.toString());
  const isActive = params.has('listView');
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);

  useEffect(() => {
    isActive ? swiper?.slideNext() : swiper?.slidePrev();
  }, [isActive, swiper]);

  const sortedVariants = product.variants?.sort((a, b) => Number(b.defaultSelected) - Number(a.defaultSelected));
  const color = escapeUrl(product.productCombinationColor ?? '');
  const size = escapeUrl(product.productCombinationSize ?? '');

  if (color !== '' && size !== '') {
    const matchingVariantIndex = sortedVariants?.findIndex((variant) => {
      const hasColor = variant.options.some((option) => option.name === 'color' && option.value === color);
      const hasSize = variant.options.some((option) => option.name === 'size' && option.value === size);
      return hasColor && hasSize;
    });

    if (matchingVariantIndex !== -1 && matchingVariantIndex !== undefined && sortedVariants?.length) {
      const [matchingVariant] = sortedVariants.splice(matchingVariantIndex, 1); // Remove the matching variant
      sortedVariants?.unshift(matchingVariant); // Add it to the beginning
    }
  }
  const defaultVariant = sortedVariants?.[0];

  return (
    <>
      <div className="relative">
        <Swiper
          spaceBetween={10}
          pagination={{ clickable: true }}
          loop={false}
          className="carousel-dark"
          onSwiper={(s) => setSwiper(s)}
        >
          <SwiperSlide>
            <div className="rounded-lg bg-white">
              <ImageRenderer
                loadingState="lazy"
                image={product.featuredImage}
                className="aspect-square rounded-lg bg-white object-contain"
              />
            </div>
          </SwiperSlide>
          {product.contextualImage && (
            <SwiperSlide>
              <div className="rounded-lg bg-white">
                <ImageRenderer
                  loadingState="lazy"
                  image={product.contextualImage}
                  className="aspect-square rounded-lg bg-white object-contain"
                />
              </div>
            </SwiperSlide>
          )}
        </Swiper>
        <div className="flex flex-col py-3">
          <LinkRenderer
            cta={{
              label: `${product.title}`,
              as: 'link',
              href: product.slug,
              linkStyle: LINK_STYLES.textBlack,
              className: 'text-base font-bold pb-1 after:inset-0 after:absolute after:z-10',
            }}
          />
          {product.showAvailableSizes && <ProductCardVariants sortedVariants={sortedVariants} />}
          {product.productCombinationColor && product.productCombinationSize && (
            <div className="capitalize">
              {product.productCombinationColor} - {product.productCombinationSize}
            </div>
          )}
          <div>
            {defaultVariant?.compareAtPrice?.amount && (
              <span className="mr-1 text-slate-400 line-through">
                {defaultVariant?.compareAtPrice.symbol}
                {defaultVariant?.compareAtPrice.amount}
              </span>
            )}
            <span>
              {defaultVariant?.price?.symbol}
              {defaultVariant?.price?.amount}
            </span>
          </div>
        </div>
      </div>
      {quickAdd && product.id && (
        <div className="flex">
          <ProductBuy
            cta={{ linkStyle: LINK_STYLES.primaryOutline, size: BUTTON_SIZE.small }}
            variantId={product.id}
            product={product}
            label="collection.quickButton"
          />
        </div>
      )}
    </>
  );
}

export default ProductItem;
