import { CartLineItemInterface } from '@qlevr/shared/interfaces';
import { priceFormat } from '../price-format';

/**
 * Calculates the total savings in the cart based on the compareAtPrice of each item.
 * @param items - The array of cart line items.
 * @param subtotalAmount - The subtotal amount of the cart.
 * @returns The total savings in the cart.
 */
export function calculateCartSavings(items: CartLineItemInterface[], subtotalAmount: string): string | null {
  const totalCompareAtPrice = items.reduce((total, item) => {
    if (item.compareAtPrice?.amount) {
      return total + Number(item.compareAtPrice.amount) * item.quantity;
    }
    return total;
  }, 0);

  const priceWithoutReduction = items.reduce((total, item) => {
    if(item.compareAtPrice?.amount) {
      return total + Number(item.compareAtPrice.amount) * item.quantity;
    }

    return total + Number(item.price?.amount);
  }, 0);

  const hasSavings = totalCompareAtPrice > 0;
  const savings = Math.abs(priceWithoutReduction - Number(subtotalAmount));

  if (!hasSavings || !savings || savings <= 0) {
    return null;
  }

  return priceFormat(savings.toString());
}
