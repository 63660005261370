'use client';

import { LanguageType, useRouter } from '@qlevr/shared/constants';
import { PathInterface } from '@qlevr/shared/interfaces';
import { getLocaleLabel } from '@qlevr/shared/utilities';
import { CustomFlowbiteTheme, Dropdown, DropdownItem, Flowbite } from 'flowbite-react';
import isEmpty from 'lodash.isempty';
import { useParams } from 'next/navigation';

/* eslint-disable-next-line */
export interface InternationalisationDropdownProps {
  localisation: PathInterface[] | null;
}

export function InternationalisationDropdown({ localisation }: InternationalisationDropdownProps) {
  const { locale } = useParams<{ locale: string }>();
  const router = useRouter();

  if (!localisation || isEmpty(localisation)) {
    return null;
  }

  const switchLocale = (path: PathInterface) => {
    router.push(path.slug, { locale: path.locale as LanguageType | undefined });
  };

  const dropdownTheme: CustomFlowbiteTheme = {
    dropdown: {
      inlineWrapper: 'flex items-center whitespace-nowrap lg:min-w-11 lg:min-h-11',
      floating: {
        base: 'z-10 w-fit rounded divide-y divide-gray-100 shadow focus:outline-none ml-0',
      },
    },
  };

  return (
    <Flowbite theme={{ theme: dropdownTheme }}>
      <li className="flex">
        <Dropdown label={getLocaleLabel(locale)} dismissOnClick={false} inline>
          {localisation.map((path, key) => (
            <DropdownItem key={key} onClick={() => switchLocale(path)}>
              {getLocaleLabel(path.locale)}
            </DropdownItem>
          ))}
        </Dropdown>
      </li>
    </Flowbite>
  );
}

export default InternationalisationDropdown;
