'use client';

import { BUTTON_SIZE, ButtonSizeType, LINK_STYLES, LinkStyleType } from '@qlevr/shared/constants';
import { LinkInterface, ProductVariantInterface } from '@qlevr/shared/interfaces';
import { useCart } from '@qlevr/shared/providers';
import { useTranslations } from 'next-intl';
import { startTransition, useRef } from 'react';
import LinkRenderer from '../link/link-renderer';
import { AttributeInputShopify } from '@qlevr/shared/schema';

/* eslint-disable-next-line */
export interface ProductBuySimpleProps {
  variantId?: ProductVariantInterface['id'];
  variant: ProductVariantInterface | null;
  cta?: Partial<LinkInterface>;
  linkStyle?: LinkStyleType;
  size?: ButtonSizeType;
  label?: string;
}

export function ProductSimpleBuy({ variantId, variant, label = 'cta.addToCart', cta }: ProductBuySimpleProps) {
  const t = useTranslations();
  const cartContext = useCart();
  const componentRef = useRef(null);
  const { add } = useCart();
  const attributes: AttributeInputShopify[] = [];

  const submitCta: LinkInterface = {
    as: 'button',
    type: 'submit',
    disabled: !variantId,
    ...(cta?.linkStyle ? { linkStyle: cta.linkStyle } : { linkStyle: LINK_STYLES.primary }),
    ...(cta?.wrapperClassName ? { wrapperClassName: cta.wrapperClassName } : { wrapperClassName: 'w-full' }),
    ...(cta?.className ? { className: cta.className } : { className: 'whitespace-nowrap' }),
    ...(cta?.size ? { size: cta.size } : { size: BUTTON_SIZE.base }),
    ...(cta?.leadingIcon ? { leadingIcon: cta.leadingIcon } : {}),
    ...(cta?.leadingClassName ? { leadingClassName: cta.leadingClassName } : {}),
  };

  async function formAction() {
    if (!variant || !variantId) return;

    startTransition(() => {
      add(variant, variantId, attributes);
    });

    cartContext.open();
  }

  return (
    <form
      ref={componentRef}
      onClick={(e) => {
        e.preventDefault();
        formAction();
      }}
      className="w-full"
    >
      <LinkRenderer cta={submitCta}>{t(label)}</LinkRenderer>
    </form>
  );
}

export default ProductSimpleBuy;
