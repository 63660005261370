import { SitemapInterface } from '@qlevr/shared/interfaces';
import { GetCollectionPathsQueryShopify } from '@qlevr/shared/schema';
import { imageShopifyMapper } from '../image/image-shopify';
import { RATIOS } from '@qlevr/shared/constants';

export function collectionSitemapMapper(collections: GetCollectionPathsQueryShopify['collections'], locale: string): SitemapInterface[] {
  return collections.edges.map((collection) => {

    const image = imageShopifyMapper(collection.node.image?.url, { ratio: RATIOS.DEFAULT, columnConfig: { xs: 1 } });

    return {
      slug: collection.node.handle,
      locale,
      updatedAt: collection.node.updatedAt,
      ...(image?.lg.url ? { image: image.lg.url } : {}),
    };
  });
}
