export const filtersModalTheme = {
  content: {
    base: 'relative h-full w-full size-chart-modal',
    inner: 'relative bg-white shadow dark:bg-gray-700 flex flex-col h-full',
  },
  body: {
    base: 'flex-1 overflow-auto px-4 lg:px-10 pb-10 pt-1.5',
  },
  header: {
    base: 'flex flex-row-reverse items-start justify-between px-4 pt-2.5',
    title: 'w-full text-center text-lg font-medium text-black dark:text-white',
    close: {
      base: 'ml-0',
      icon: 'h-6 w-6',
    },
  },
  footer: {
    base: 'border-none px-6 py-6 lg:px-10 rounded-t-lg empty:hidden',
  },
};
