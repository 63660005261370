import { GetCartQueryShopify } from '@qlevr/shared/schema';

export function getCartProductIds(cart: GetCartQueryShopify['cart']): Set<string> | null {
  if (!cart) {
    return null;
  }

  const productIds = new Set<string>();

  cart.lines.edges.forEach(({ node }) => {
    productIds.add(node.merchandise.product.id)
  });

  return productIds
}
