import { CollectionCardInterface, ContentProductGridInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';
import { sectionMapper } from '../section/section';

export function mapProductGrid(collection: CollectionCardInterface, quickAdd : boolean | undefined): ContentProductGridInterface | null {
  if(!collection || isEmpty(collection.products)) {
    return null
  }

  return {
    _uid: 'productGrid',
    component: 'productGrid',
    collection: collection,
    products: collection.products,
    quickAdd: quickAdd ?? false,
    section: sectionMapper(undefined),
  };
}
