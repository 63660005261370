'use client';

import { ICONS } from '@qlevr/shared/constants';
import { ImageInterface, ProductInterface } from '@qlevr/shared/interfaces';
import { GuidedBuyingFlowContext } from '@qlevr/shared/providers';
import {
  calculateDiscountPrice,
  getProductVariantByOptionName,
  getSizeColorVariantFromUrl,
  reverseEscapeUrl,
} from '@qlevr/shared/utilities';
import { useParams, useSearchParams } from 'next/navigation';
import { useContext, useEffect, useRef, useState } from 'react';
import IconRenderer from '../../../icons/icon-renderer.component';
import ImageRenderer from '../../../image-renderer/image-renderer';

export interface GuidedBuyingFlowMainProductCardProps {
  product: ProductInterface | null;
}

export function GuidedBuyingFlowMainProductCard({ product }: GuidedBuyingFlowMainProductCardProps) {
  const { addVariantId, replaceVariantId,removeVariantId, changeVariant, variant } = useContext(GuidedBuyingFlowContext);
  const [activeImage, setActiveImage] = useState<ImageInterface | null>(product?.featuredImage ?? null);
  const previousVariantId = useRef<string | null>(null);

  const searchParams = useSearchParams();
  const params = useParams<{ variant: string }>();
  const variantInfo = getSizeColorVariantFromUrl(params);
  const selectedVariant = getProductVariantByOptionName(
    product?.variants ?? [],
    searchParams,
    variantInfo?.size,
    variantInfo?.color,
  );

  // Set the first available variant as the selected variant
  useEffect(() => {
    if (selectedVariant && !variant) {
      changeVariant(selectedVariant);
    } else if (!selectedVariant && product?.firstAvailableVariant) {
      changeVariant(product.firstAvailableVariant);
    }
  }, [selectedVariant, variant, changeVariant, product]);

  useEffect(() => {
    const uniqueKey = `main-${product?.id}`;

    if (selectedVariant && product?.id) {
      setActiveImage(selectedVariant.images?.[0]?.image || product.featuredImage || null);
      if (previousVariantId.current) {
        replaceVariantId(uniqueKey, product.id, previousVariantId.current, selectedVariant.id);
      } else {
        addVariantId(uniqueKey, product.id, selectedVariant.id);
      }
      previousVariantId.current = selectedVariant.id;
    } else {
      setActiveImage(product?.featuredImage ?? null);
    }

    // Clean up previous variant ID
    return () => {
      if (previousVariantId.current && product?.id) {
        removeVariantId(uniqueKey, product.id, previousVariantId.current);
        previousVariantId.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariant, product]);

  if (!product) {
    return null;
  }

  return (
    <div className="flex flex-col items-end justify-between rounded-lg border-gray-300 p-2 bg-white space-y-2 relative my-4">
      <div className="flex w-full">
        {activeImage && <ImageRenderer image={activeImage} className="w-full max-w-20 h-20 mr-4 rounded-lg" />}
        <div className="w-full">
          <div className="text-base font-bold">{product.title}</div>
          <div className="text-xs leading-5 font-normal flex flex-col mb-1">
            {variant?.options.map((option) => (
              <span key={option.name}>
                <span className="capitalize">{option.name}:</span> {reverseEscapeUrl(option.value)}
              </span>
            ))}
          </div>
          <div className="flex justify-between items-center">
            <div className="text-base font-serif flex gap-2">
              <span className={`${product.discountPercentage ? 'line-through text-slate-400' : ''}`}>
                {selectedVariant?.price?.amount && (
                  <span>
                    {selectedVariant.price?.symbol}
                    {selectedVariant.price?.amount}
                  </span>
                )}
              </span>
              {product.discountPercentage && selectedVariant?.price?.amount && (
                <span>
                  {selectedVariant?.price?.symbol}
                  {calculateDiscountPrice(selectedVariant?.price?.amount, product.discountPercentage ?? 0)}
                </span>
              )}
            </div>

            <button
              className={`font-bold text-xs px-4 py-2 rounded-full flex items-center gap-1 text-white bg-green-500`}
            >
              Added <IconRenderer icon={ICONS.checkmark} className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GuidedBuyingFlowMainProductCard;
