'use client';

import { BUTTON_SIZE, ICONS, LINK_STYLES } from '@qlevr/shared/constants';
import { CartInterface } from '@qlevr/shared/interfaces';
import isEmpty from 'lodash.isempty';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import { WithFreeShippingProps } from '../../free-shipping/free-shipping-hoc';
import LinkRenderer from '../../link/link-renderer';

/* eslint-disable-next-line */
export interface CartModalFooterProps {
  cart?: CartInterface;
  locale: string;
  isFreeShipping: boolean;
  progress: number;
  amountLeft: string;
  shippingRate?: number | null;
}

export function CartModalFooter({ cart, isFreeShipping, shippingRate }: CartModalFooterProps) {
  const t = useTranslations();
  const router = useRouter();

  if (!cart || isEmpty(cart.items)) {
    return null;
  }


  const shippingLabel = isFreeShipping
    ? `${t('cart.shipping.free')}`
    : shippingRate
      ? `${cart.cost.subtotalAmount?.symbol}${shippingRate}`
      : `${t('cart.shipping.totalShipping')}`;

  const totalAmount = 
    !isFreeShipping && shippingRate
      ? ((cart.cost.totalAmount?.amount ?? 0) + shippingRate).toFixed(2)
      : (cart.cost.totalAmount?.amount ?? 0).toFixed(2);

  return (
    <>
      <div className="flex flex-col space-y-1">
        <div className="flex justify-between items-center">
          <span>{t('cart.footer.subtotal')}</span>
          <span>
            {cart.cost.subtotalAmount?.symbol}
            {cart.cost.subtotalAmount?.amount}
          </span>
        </div>
        {Number(cart.savings) > 0 && (
          <div className="flex justify-between items-center text-green-700 font-semibold">
            <span>{t('cart.footer.youSave')}</span>
            <span>
              {cart.cost.subtotalAmount?.symbol}
              {cart.savings}
            </span>
          </div>
        )}
        <div className="flex justify-between items-center">
          <span>{t('cart.footer.shipping')}</span>
          <span className="text-green-700">{shippingLabel}</span>
        </div>
        <div className="border-t my-1 border-slate-300"></div>
        <div className="flex justify-between items-center">
          <span>{t('cart.footer.total')}</span>
          <span className="font-bold">
            {cart.cost.totalAmount?.symbol}
            {totalAmount}
          </span>
        </div>
      </div>
      <div className="flex flex-col space-y-4 mt-4">
          <LinkRenderer
            cta={{
              label: `${t('cart.checkout')}`,
              as: 'button',
              onClick: () => router.push(cart?.checkoutUrl as string),
              linkStyle: LINK_STYLES.primary,
              size: BUTTON_SIZE.base,
              trailingIcon: cart.pending ? ICONS.spinner : ICONS.lock,
              trailingClassName: `w-4 h-4 ${cart.pending ? 'inline animate-spin text-gray-200 fill-green-500' : ''}`,
              disabled: cart.pending,
              wrapperClassName: `${cart.pending ? 'opacity-80' : ''}`,
            }}
          />
      </div>
    </>
  );
}

export default WithFreeShippingProps<CartModalFooterProps>(CartModalFooter);
