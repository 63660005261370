import { GlobalInterface } from '@qlevr/shared/interfaces';
import { GlobalStoryblok } from '@qlevr/shared/schema';
import { StoryblokStory } from 'storyblok-generate-ts';
import { mapCartShippingRate, mapEmptyCart } from '../cart/cart';
import { linksMapper } from '../link/link';
import { globalsMetatagsMapper } from '../metatags/metatags';
import { mapNewsletterSubscribe } from '../newsletter/newsletter-subscribe';
import { mapRichText } from '../rich-text/rich-text';
import { mapBanner } from './banner';
import { mapFooter } from './footer';
import { mapHeader } from './header';
import { mapProductDeliveryContent } from './product-delivery';

export function globalsMapper(globals?: StoryblokStory<GlobalStoryblok>): GlobalInterface | null {
  if (!globals) return null;

  return {
    banner: mapBanner(globals?.content?.banner),
    brandName: globals.content.brandName,
    copyrightText: mapRichText(globals?.content?.copyrightText),
    deliveryNotes: mapProductDeliveryContent(globals?.content?.productDeliveryContent),
    disclaimerLinks: linksMapper(globals?.content?.disclaimerLinks),
    termsAndConditions: linksMapper(globals?.content?.termsAndConditions),
    disclaimerText: mapRichText(globals?.content?.disclaimerText),
    emptyCart: mapEmptyCart(globals.content.cart?.[0]),
    footer: mapFooter(globals?.content?.footer),
    freeShippingThreshold: +globals?.content?.freeShippingThreshold ?? 0,
    header: mapHeader(globals?.content?.header),
    inventoryThreshold: +globals?.content?.inventoryThreshold ?? 0,
    metatags: globalsMetatagsMapper(globals),
    socialLinks: linksMapper(globals?.content?.socialLinks),
    newsletter: mapNewsletterSubscribe(globals.content),
    shippingRate: mapCartShippingRate(globals?.content?.shippingRate) ?? 0,
  };
}
