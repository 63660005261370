import { VariantInfoImagesContentInterface } from '@qlevr/shared/interfaces';
import { MetaobjectFieldShopify, MetaobjectShopify } from '@qlevr/shared/schema';
import { getMetafieldReferenceImageField } from '../get-metafield-reference-image-field';
import { getMetafieldsByKey } from '../get-metafields-by-key';
import isEmpty from 'lodash.isempty';

export function getInfoImagesContentReference(
  field_key: string,
  fields: MetaobjectFieldShopify[],
): VariantInfoImagesContentInterface | null {
  if (!fields || isEmpty(fields)) {
    return null;
  }

  const items: VariantInfoImagesContentInterface[] = [];
  const field = fields.find((item) => item.key === field_key);

  if (!field?.reference) {
    return null;
  }

  const key = getMetafieldsByKey('title', (field.reference as MetaobjectShopify).fields);
  const value = getMetafieldReferenceImageField('icon', (field.reference as MetaobjectShopify).fields);
  const text = getMetafieldsByKey('text', (field.reference as MetaobjectShopify).fields);

  if (!key || !text || !value) {
    return null;
  }

  items.push({ key, value, text });

  return items[0] || null;
}
